import z from "zod";
import { DynamicFormDef, DynamicFormField } from "./types";
import { DateTime } from "luxon";

export const generateFormSchema = (
  fields: DynamicFormDef["fields"],
  values: Record<string, any>
) => {
  const getBaseFieldSchema = (field: DynamicFormField): z.ZodTypeAny => {
    switch (field.type) {
      case "string":
      case "text":
      case "password":
        return z.string();
      case "number":
        return z.preprocess((val) => Number(val), z.number());
      case "date":
        return z
          .string()
          .refine(
            (val) => DateTime.fromISO(val).isValid,
            `${field.label} is not a valid date`
          );
      case "checkbox":
        return z.boolean();
      case "select":
        return z.string();
      default:
        return z.any();
    }
  };

  const buildFieldSchema = (field: DynamicFormField): z.ZodTypeAny => {
    let fieldSchema = getBaseFieldSchema(field);

    // Apply non-conditional validations
    const nonConditionalValidations =
      field.validations?.filter((v) => !v.dependsOn) ?? [];

    nonConditionalValidations.forEach((validation) => {
      fieldSchema = applyValidation(fieldSchema, field, validation);
    });

    return fieldSchema.optional(); // Make optional by default; required validations will enforce presence
  };

  const applyValidation = (
    schema: z.ZodTypeAny,
    field: DynamicFormField,
    validation: any
  ): z.ZodTypeAny => {
    switch (field.type) {
      case "string":
      case "text":
      case "password":
        const _fieldSchema = schema as z.ZodString;
        if (validation.required) {
          schema = _fieldSchema.min(
            1,
            validation.errorMessage || `${field.label} is required`
          );
        }
        if (validation.minLength != null) {
          schema = _fieldSchema.min(
            validation.minLength,
            validation.errorMessage ||
              `${field.label} must be at least ${validation.minLength} characters`
          );
        }
        if (validation.maxLength != null) {
          schema = _fieldSchema.max(
            validation.maxLength,
            validation.errorMessage ||
              `${field.label} must be at most ${validation.maxLength} characters`
          );
        }
        if (validation.pattern) {
          schema = _fieldSchema.regex(
            new RegExp(validation.pattern),
            validation.errorMessage || `${field.label} is invalid`
          );
        }
        break;
      case "number":
        const _numberSchema = z.preprocess((val) => Number(val), z.number());
        if (validation.required) {
          // Required by default
        }
        if (validation.min != null) {
          schema = _numberSchema.refine(
            (val) => val >= validation.min,
            validation.errorMessage ||
              `${field.label} must be at least ${validation.min}`
          );
        }
        if (validation.max != null) {
          schema = _numberSchema.refine(
            (val) => val <= validation.max,
            validation.errorMessage ||
              `${field.label} must be at most ${validation.max}`
          );
        }
        break;
      case "checkbox":
        if (validation.required) {
          schema = schema.refine(
            (val) => val === true,
            validation.errorMessage || `${field.label} is required`
          );
        }
        break;
      case "select":
        const _selectSchema = schema as z.ZodString;
        if (validation.required) {
          schema = _selectSchema.min(
            1,
            validation.errorMessage || `${field.label} is required`
          );
        }
        break;
      default:
        break;
    }

    return schema;
  };

  // Build the base schema
  const fieldSchemas: Record<string, z.ZodTypeAny> = {};
  fields.forEach((field) => {
    // Skip fields that are not visible
    if (!isFieldVisible(field, values)) {
      return;
    }

    // Skip fields that are not visible
    fieldSchemas[field.name] = buildFieldSchema(field);
  });

  // Build the form schema
  const formSchema = z.object(fieldSchemas).superRefine((allValues, ctx) => {
    fields.forEach((field) => {
      const fieldValue = allValues[field.name];

      // Conditional Validations
      const fieldValidations = field.validations ?? [];
      fieldValidations
        .filter((v) => v.dependsOn)
        .forEach((validation) => {
          const dependentValue = validation.dependsOn
            ? allValues[validation.dependsOn]
            : undefined;
          const isDependentFieldVisible = isFieldVisibleByName(
            fields,
            validation.dependsOn,
            allValues
          );
          if (
            isDependentFieldVisible &&
            dependentValue === validation.equalsValue
          ) {
            const tempSchema = applyValidation(
              getBaseFieldSchema(field),
              field,
              validation
            );

            const result = tempSchema.safeParse(fieldValue);
            if (!result.success) {
              result.error.errors.forEach((error) => {
                ctx.addIssue({
                  path: [field.name, ...(error.path || [])],
                  message: error.message,
                  code: z.ZodIssueCode.custom,
                });
              });
            }
          }
        });

      // Field Calculations (Ensure calculated fields have correct values)
      if (field.calculation) {
        const expectedValue = evaluateCalculation(field.calculation, allValues);
        if (fieldValue !== expectedValue) {
          ctx.addIssue({
            path: [field.name],
            message: `${field.label} is incorrect.`,
            code: z.ZodIssueCode.custom,
          });
        }
      }
    });
  });

  return formSchema;
};

export function isFieldVisible(
  field: DynamicFormField,
  values: Record<string, any>
): boolean {
  if (!field.visibilityConditions || field.visibilityConditions.length === 0) {
    return true;
  }

  return field.visibilityConditions.every((condition) => {
    const dependentValue = values[condition.dependsOn];
    return dependentValue === condition.equalsValue;
  });
}

export function isFieldVisibleByName(
  fields: DynamicFormField[],
  fieldName: string | undefined,
  values: Record<string, any>
): boolean {
  if (!fieldName) {
    return true;
  }
  const field = fields.find((f) => f.name === fieldName);
  if (!field) {
    return true;
  }
  return isFieldVisible(field, values);
}

// export function shouldApplyValidation(
//   validation: any,
//   values: Record<string, any>
// ): boolean {
//   if (!validation.dependsOn) {
//     return true;
//   }

//   const dependentValue = values[validation.dependsOn];
//   return dependentValue === validation.equalsValue;
// }

// SECURITY: This function is unsafe and should be used with caution,
// new Function() can execute arbitrary code similar to eval()
export function evaluateCalculation(
  calculation: string,
  values: Record<string, any>
): any {
  // Create a new function with field names as parameters
  // Be cautious with security here - avoid evaluating arbitrary code
  // For safety, you might consider using a library like mathjs
  const functionBody = `return ${calculation};`;

  // Extract field names used in the calculation
  const fieldNames = Object.keys(values);

  // Create a function with field names as arguments
  try {
    // eslint-disable-next-line no-new-func
    const calcFunction = new Function(...fieldNames, functionBody);
    const args = fieldNames.map((name) => values[name] || 0);
    return calcFunction(...args);
  } catch (error) {
    console.error("Error evaluating calculation:", error);
    return null;
  }
}
