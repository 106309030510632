// FieldOptions.tsx
import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { Input } from "components/placement/Input";
import Button from "components/placement/Button";
import { TrashIcon } from "lucide-react";
import Card from "components/Card";
import { uuid } from "helpers/common";
import { FieldListHeader } from "../../field_list_header";
import { DynamicFormBuilderDef } from "../../types";

type FieldOptionsProps = {
  reactForm: UseFormReturn<DynamicFormBuilderDef>;
  fieldIndex: number;
};

const FieldOptions: React.FC<FieldOptionsProps> = ({
  reactForm,
  fieldIndex,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: reactForm.control,
    name: `fields.${fieldIndex}.options`,
  });

  const handleAddOption = () => {
    const fieldId = reactForm.getValues(`fields.${fieldIndex}.id`);

    append({
      id: uuid(),
      value: "",
      label: "",
      fieldId,
      conditions: [],
    });
  };

  return (
    <div className="mt-4">
      <FieldListHeader title="Options" onAddField={handleAddOption} />
      {fields.map((option, optionIndex) => {
        return (
          <Card key={option.id} className="p-3 mt-2">
            <div className="flex justify-between items-center">
              <h5 className="text-sm font-medium">Option {optionIndex + 1}</h5>
              <Button
                type="button"
                size="icon"
                onClick={() => remove(optionIndex)}
              >
                <TrashIcon className="w-4 h-4" />
                <span className="sr-only">Remove</span>
              </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div>{option.id}</div>
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.options.${optionIndex}.label`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Label</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Option Label" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.options.${optionIndex}.value`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Value</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Option Value" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default FieldOptions;
