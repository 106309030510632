import React, { useState } from "react";
import FormList from "components/dynamic_form/builder/list";
import FormEditor from "components/dynamic_form/builder/editor";
import {
  useDynamicFormBuilderDelete,
  useDynamicFormBuilderGetMany,
} from "hooks/useDynamicForm";
import { toast } from "react-toastify";
import PageHeader from "components/PageHeader";
import { isSuperUser, useUser } from "hooks/useUser";
import Button from "components/placement/Button";
import { PlusCircleIcon } from "lucide-react";
import {
  DynamicFormBuilderDef,
  DynamicFormListItemDef,
} from "components/dynamic_form/builder/types";

const FormBuilderPage: React.FC = () => {
  const { user } = useUser();

  const editorFormRef = React.useRef<HTMLDivElement>(null);

  const [selectedFormId, setSelectedFormId] = useState<string>("");
  const [showFormEditor, setShowFormEditor] = useState<boolean>(false);

  const {
    data: forms,
    isLoading: isFormsIsLoading,
    isFetched: isFormsFetched,
    refetch: fetchForms,
  } = useDynamicFormBuilderGetMany({
    enabled: true,
    onSuccess: () => {
      // Do nothing
    },
    onError: (errMessage) => {
      console.error("An error occurred while fetching forms:", errMessage);
      toast.error("An error occurred while fetching forms!");
    },
  });

  const { mutate: mutateDeleteForm } = useDynamicFormBuilderDelete({
    onSuccess: async () => {
      await fetchForms();
      toast.success("Form deleted successfully!");
    },
    onError: (errMessage) => {
      console.error("An error occurred while deleting the form:", errMessage);
    },
  });

  const handleFormSelect = (form: DynamicFormListItemDef) => {
    setSelectedFormId(form.id);
    setShowFormEditor(true);
    editorFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFormCreate = () => {
    setSelectedFormId("");
    setShowFormEditor(true);
  };

  const handleFormSave = async (
    form: DynamicFormBuilderDef,
    close?: boolean
  ) => {
    setSelectedFormId(form.id);
    if (close) setShowFormEditor(false);
  };

  const handleFormCancel = () => {
    setSelectedFormId("");
    setShowFormEditor(false);
    fetchForms();
  };

  // TODO: Implement handleFormDelete
  const handleFormDelete = async (formId: string) => {
    setSelectedFormId("");
    setShowFormEditor(false);
    mutateDeleteForm(formId);
  };

  return (
    <div className="mt-4 grid grid-cols-2 gap-4 px-4">
      <PageHeader className="col-span-full">
        <div className="flex flex-row justify-between">
          <div className="text-xl">Dynamic Form Builder</div>
          {isSuperUser(user) && !showFormEditor && (
            <div className="">
              <Button
                type="button"
                variant="primary"
                onClick={handleFormCreate}
              >
                <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
              </Button>
            </div>
          )}
        </div>
      </PageHeader>
      <div className="col-span-full">
        {isFormsIsLoading && <div>Loading...</div>}
        {isFormsFetched && !forms && <div>No data found</div>}
        {isFormsFetched && forms && (
          <div className="space-y-4">
            {showFormEditor && (
              <FormEditor
                ref={editorFormRef}
                selectedFormId={selectedFormId}
                onCancel={handleFormCancel}
                onSave={handleFormSave}
              />
            )}
            {!showFormEditor && (
              <FormList
                forms={forms}
                onSelect={handleFormSelect}
                onDelete={handleFormDelete}
                onCreate={handleFormCreate}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormBuilderPage;
