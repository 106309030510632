// formDataSchema.ts
import { isNullEmptyOrWhitespace } from "helpers/common";
import { z } from "zod";

export const fieldValidationSchema = z.object({
  id: z.string().min(1, { message: "Validation ID is required" }),
  fieldId: z.string().min(1),
  required: z.boolean().optional(),
  minLength: z.preprocess(
    (val) => (isNullEmptyOrWhitespace(val) ? undefined : Number(val)),
    z.number().optional()
  ),
  maxLength: z.preprocess(
    (val) => (isNullEmptyOrWhitespace(val) ? undefined : Number(val)),
    z.number().optional()
  ),
  pattern: z.string().optional(),
  min: z.preprocess(
    (val) => (isNullEmptyOrWhitespace(val) ? undefined : Number(val)),
    z.number().optional()
  ),
  max: z.preprocess(
    (val) => (isNullEmptyOrWhitespace(val) ? undefined : Number(val)),
    z.number().optional()
  ),
  validate: z.string().optional(),
  errorMessage: z.string().optional(),
  dependsOn: z.string().optional(),
  equalsValue: z.string().optional(),
});

export const fieldOptionSchema = z.object({
  id: z.string().min(1, { message: "Option ID is required" }),
  fieldId: z.string().min(1),
  value: z.string(),
  label: z.string(),
  conditions: z.array(z.any()).optional(), // Adjust as per your condition structure
});

export const fieldConditionSchema = z
  .object({
    id: z.string().min(1, { message: "Condition ID is required" }),
    dependsOn: z.string(),
    equalsValue: z.string(),
    fieldId: z.string().optional(), // Either fieldId or optionId must be provided
    optionId: z.string().optional(),
  })
  .refine((data) => {
    if (
      isNullEmptyOrWhitespace(data.fieldId) &&
      isNullEmptyOrWhitespace(data.optionId)
    ) {
      return {
        message: "Either fieldId or optionId must be provided",
      };
    }

    if (
      !isNullEmptyOrWhitespace(data.fieldId) &&
      !isNullEmptyOrWhitespace(data.optionId)
    ) {
      return {
        message: "Either fieldId or optionId must be provided, not both",
      };
    }

    return true;
  });

export const fieldSchema = z.object({
  id: z.string().min(1, { message: "Field ID is required" }),
  formId: z.string().min(1),
  name: z.string().min(1),
  label: z.string().min(1),
  type: z.string().min(1),
  calculation: z.string().optional(),
  options: z.array(fieldOptionSchema).optional(),
  visibilityConditions: z.array(fieldConditionSchema).optional(),
  validations: z.array(fieldValidationSchema).optional(),
  order: z.preprocess((val) => Number(val), z.number()),
});

export const formSchema = z.object({
  id: z.string().min(1),
  name: z.string(),
  fields: z.array(fieldSchema),
});

// To validate formData:
// formDataSchema.parse(formData);
