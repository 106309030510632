import { flexRender, type Table } from "@tanstack/react-table";
import Button from "components/placement/Button";
import { FileDownIcon } from "lucide-react";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

interface DataTableExportProps<TData> {
  table: Table<TData>;
}

export function DataTableExport<TData>({ table }: DataTableExportProps<TData>) {
  const exportData = () => {
    const csvData = table.getRowModel().rows.map((row) => {
      const rowValues = row.getVisibleCells().reduce((acc, cell) => {
        const headerId = cell.column.columnDef.header as string;
        // acc[headerId] = cell.getValue() as string;
        const cellValue = flexRender(
          cell.column.columnDef.cell,
          cell.getContext()
        );

        try {
          const extractedValue = extractText(cellValue, table);
          acc[headerId] = extractedValue;
        } catch (e) {
          console.error(e);
          acc[headerId] = cell.getValue() as string;
        }
        return acc;
      }, {} as Record<string, string>);
      return rowValues;
    });

    import("export-from-json").then(({ default: exportFromJSON }) => {
      exportFromJSON({
        data: csvData,
        fileName: "data.csv",
        exportType: "csv",
      });
    });
  };

  return (
    <Button
      variant="outline"
      size="sm"
      className="ml-auto h-8 flex"
      onClick={exportData}
    >
      <FileDownIcon className="mr-2 h-4 w-4" />
      Export
    </Button>
  );
}

function preprocessElements(
  element: React.ReactElement
): React.ReactElement | null {
  const reactElement = typeof element.type === "function" && element.type.name === "cell" ? 
    (element.type as any)(element.props)
    : element;

  // If the element is not an object or doesn't have props, return it as is.
  if (typeof reactElement !== "object" || !reactElement.props) {
    return reactElement;
  }  

  // Check if the element has the class 'export-none' in its className.
  const className = reactElement.props?.className ?? "";
  if (className.includes("export-none")) {
    // Return null or any placeholder/alternative if you want to remove this element.
    return null;
  }

  // Recursively process children if any.
  const children = reactElement?.props?.children && React.Children.map(
    reactElement.props.children,
    preprocessElements
  );

  // Return a cloned element with possibly processed children.
  return React.cloneElement(reactElement, { ...reactElement.props }, children);
}

function extractText(content: any, table: Table<any>): string {
  if (typeof content === "string") {
    return content;
  }
  if (typeof content === "function") {
    return extractText(content({ table }), table);
  }
  // console.log(content);
  // Preprocess the component tree before rendering it
  const processedTree = preprocessElements(content);
  if (!processedTree) {
    return "";
  }

  // Render to static markup
  const htmlString = renderToStaticMarkup(processedTree);

  return htmlString.replace(/<[^>]+>/g, "");
}
