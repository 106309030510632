import * as React from "react";

import { cn } from "helpers/common";
import { cva, type VariantProps } from "class-variance-authority";

const inputVariants = cva(
  "block w-full border border-gray-300 text-gray-900 shadow-sm ring-offset-primary file:border-0 file:bg-white file:text-sm file:font-medium placeholder:text-gray-400 focus:border-gray-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "",
        ghost:
          "border-transparent bg-transparent shadow-none focus:border-transparent focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-0",
        // overlap: "",
      },
      customSize: {
        default: "py-2 px-3 text-sm leading-5 focus-visible:ring-offset-2",
        sm: "py-1 px-2 text-xs leading-4 focus-visible:ring-offset-1",
        xs: "py-0.5 px-1 text-xs leading-4 focus-visible:ring-offset-0",
        lg: "py-3 px-4 text-base leading-6 focus-visible:ring-offset-2",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        customSize: ["default", "lg"],
        class: "rounded-md",
      },
      {
        variant: "default",
        customSize: ["sm", "xs"],
        class: "rounded-sm",
      },
    ],
    defaultVariants: {
      variant: "default",
      customSize: "default",
    },
  }
);

const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> &
    VariantProps<typeof inputVariants>
>(({ className, variant, customSize, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(inputVariants({ variant, customSize }), className)}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
