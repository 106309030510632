import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "helpers/common";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-gray-200 bg-gray-100 text-gray-500",
        primary: "border-primary-lighter bg-primary-lightest text-primary",
        secondary:
          "border-secondary-lighter bg-secondary-lightest text-secondary-light",
        success: "border-success-400 bg-success-50 text-success-600",
        warning: "border-warning-400 bg-warning-50 text-warning-600",
        danger: "border-danger-400 bg-danger-50 text-danger-600",
        outline: "border-gray-300 bg-white text-gray-600",
      },
      size: {
        default: "",
        sm: "",
        xs: "",
        lg: "",
        icon: "",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
