import { XIcon } from "lucide-react";
import Button from "./Button";

const ClearButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  if (!onClick) {
    return null;
  }

  return (
    <Button className="absolute right-0 flex items-center pr-1 cursor-pointer text-gray-400" size="icon" variant="text" asChild onClick={(ev) => {
      ev.preventDefault();
      onClick?.();
    }}>
      <XIcon className="h-6 w-6" />
    </Button>
  );
};

export default ClearButton;