import { z } from "zod";
import { fieldSchema } from "./form_schema";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { Input } from "components/placement/Input";
import { Textarea } from "components/placement/Textarea";
import { Checkbox } from "components/placement/Checkbox";
import { isNullEmptyOrWhitespace } from "helpers/common";

type DynamicFieldProps = {
  form: UseFormReturn<FieldValues, any, undefined>;
  fieldDef: z.infer<typeof fieldSchema>;
};
const DynamicField = ({ form, fieldDef }: DynamicFieldProps) => {
  switch (fieldDef.type) {
    case "string":
    case "text":
      return (
        <FormField
          key={fieldDef.id}
          control={form.control}
          name={fieldDef.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{fieldDef.label}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "textarea":
      return (
        <FormField
          key={fieldDef.id}
          control={form.control}
          name={fieldDef.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{fieldDef.label}</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "number":
      return (
        <FormField
          key={fieldDef.id}
          control={form.control}
          name={fieldDef.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{fieldDef.label}</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "date":
      return (
        <FormField
          key={fieldDef.id}
          control={form.control}
          name={fieldDef.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{fieldDef.label}</FormLabel>
              <FormControl>
                <Input type="date" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "checkbox":
      return (
        <FormField
          key={fieldDef.id}
          control={form.control}
          name={fieldDef.name}
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>{fieldDef.label}</FormLabel>
              </div>
            </FormItem>
          )}
        />
      );
    case "select":
      // const selectField = field as typeof field & { options: { id: string; value: string; label: string }[] };
      return (
        <FormField
          key={fieldDef.id}
          control={form.control}
          name={fieldDef.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{fieldDef.label}</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="-- Select --" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {!isNullEmptyOrWhitespace(fieldDef.options) &&
                      fieldDef.options.map((option) => (
                        <SelectItem key={option.id} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    default:
      return null;
  }
};

export default DynamicField;
