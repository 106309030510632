import React, { createContext, useCallback, useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useUser } from "hooks/useUser";
import { protectedResources } from "authConfig";

export interface IConfigContext {
  data: Record<string, string>;
}

const ConfigContext = createContext({} as IConfigContext);

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const { isSignedIn } = useUser();

  const [data, setData] = useState<Record<string, string>>({});

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/config-get");

    setData(data?.d ?? {});

    return data;
  }, [execute]);

  useEffect(() => {
    if (isSignedIn) {
      fetchData();
    }
  }, [isSignedIn, fetchData]);

  return (
    <ConfigContext.Provider
      value={{
        data,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider as default };
