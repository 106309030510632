import { AppDataProvider } from "context/AppDataProvider";
import ConfigProvider from "context/ConfigProvider";
import FarmProvider from "context/FarmProvider";
import FormProvider from "context/FormProvider";
import MenuProvider from "context/MenuProvider";
import { ModalProvider } from "context/ModalProvider";
import { NavProvider } from "context/NavProvider";
import SidebarProvider from "context/SidebarProvider";
import { UserProvider } from "context/UserProvider";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  useLocation,
} from "react-router-dom";
import RootLayout from "./RootLayout";
import LoginPage from "pages/user/login";
import DebugPage from "pages/Debug";
import HomePage from "pages/index";
import { FormPage } from "./FormPage";
import { FormListPage } from "./FormListPage";
import SyncManagerPage from "pages/SyncManager";
import Error404Page from "pages/Error404";
import Error500 from "pages/Error500";
import { ActiveMenuProvider } from "hooks/useActiveMenu";
import NetworkStatusProvider from "context/NetworkStatusProvider";
import { useUser } from "hooks/useUser";
import OfflineNotification from "components/OfflineNotification";
import SyncDataProvider from "context/SyncDataProvider";
import SyncNotification from "components/SyncNotification";
import { Suspense, lazy } from "react";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { TooltipProvider } from "components/placement/Tooltip";
import ConfigPage from "pages/config";
import { authConfig } from "authConfig";
import SampleDynamicFormPage from "pages/sample_dynamic_form_page";
import SampleDynamicTableFormPage from "pages/sample_dynamic_table_form_page";
import FormBuilderPage from "pages/config/dynamic_forms/builder";
import ConfigStandardsPage from "pages/config/standards";

const PlanningPlacementPage = lazy(() => import("pages/planning/placement"));
const PlanningPlacementCreatePage = lazy(
  () => import("pages/planning/placement/create")
);
const PlanningPulletChartPage = lazy(
  () => import("pages/planning/placement/pullet_chart")
);
const PlanningLayingChartPage = lazy(
  () => import("pages/planning/placement/laying_chart")
);

export const PrivateRoute: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isSignedIn } = useUser();
  const location = useLocation();

  if (authConfig.mode === "b2c") {
    // Not required to navigate to login page if using B2C
    return <>{children}</>;
  }

  if (!isSignedIn) {
    return <Navigate to="/user/login" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/user/login" element={<LoginPage />} />

      <Route
        path="/"
        element={
          <NetworkStatusProvider>
            <UserProvider>
              <AppDataProvider>
                <ConfigProvider>
                  <FarmProvider>
                    <FormProvider>
                      <MenuProvider>
                        <SidebarProvider>
                          <NavProvider>
                            <ModalProvider>
                              <ActiveMenuProvider>
                                <PrivateRoute>
                                  <SyncDataProvider>
                                    <OfflineNotification />
                                    <SyncNotification />
                                    <Suspense fallback={<FieldsetSkeleton />}>
                                      <TooltipProvider>
                                        <RootLayout />
                                      </TooltipProvider>
                                    </Suspense>
                                  </SyncDataProvider>
                                </PrivateRoute>
                              </ActiveMenuProvider>
                            </ModalProvider>
                          </NavProvider>
                        </SidebarProvider>
                      </MenuProvider>
                    </FormProvider>
                  </FarmProvider>
                </ConfigProvider>
              </AppDataProvider>
            </UserProvider>
          </NetworkStatusProvider>
        }
        errorElement={<Route path="500" element={<Error500 />} />}
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/planning/placement" element={<PlanningPlacementPage />} />
        <Route
          path="/planning/placement-replicate"
          element={<PlanningPlacementCreatePage />}
        />
        <Route
          path="/planning/placement-pullet-chart"
          element={<PlanningPulletChartPage />}
        />
        <Route
          path="/planning/placement-laying-chart"
          element={<PlanningLayingChartPage />}
        />
        <Route path="/:module" element={<FormListPage />} />
        <Route path="/:module/:moduleFeatureGroup" element={<FormListPage />} />
        <Route
          path="/:module/:moduleFeatureGroup/:formId"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:id"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:parentFormId/:parentId"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:id/:parentFormId/:parentId"
          element={<FormPage />}
        />
        <Route path="/debug" element={<DebugPage />} />
        <Route path="/sync-manager" element={<SyncManagerPage />} />
        <Route path="/admin/config" element={<ConfigPage />} />
        <Route
          path="/admin/config/dynamic-forms"
          element={<FormBuilderPage />}
        />
        {/*<Route path="/admin/config/:formId" element={<SampleDynamicFormListPage />} />*/}{" "}
        {/* Show list of dynamic form record */}
        <Route
          path="/admin/config/:formId/new"
          element={<SampleDynamicFormPage />}
        />{" "}
        {/* Show dynamic form new page */}
        <Route
          path="/admin/config/:formId/edit/:id"
          element={<SampleDynamicFormPage />}
        />{" "}
        {/* Show dynamic form edit page */}
        <Route
          path="/admin/config/:formId/editor"
          element={<SampleDynamicTableFormPage />}
        />{" "}
        {/* Show dynamic table form edit page */}
        {/* <Route path="/admin/config/user" element={<ConfigUserPage />} />
        <Route
          path="/admin/config/user/create"
          element={<ConfigUserCreatePage />}
        />
        <Route path="/admin/config/user/:id" element={<ConfigUserEditPage />} />
        <Route
          path="/admin/config/standards"
          element={<ConfigStandardsPage />}
        /> */}
        <Route
          path="/admin/config/standards"
          element={<ConfigStandardsPage />}
        />
        <Route path="500" element={<Error500 />} />
        <Route path="*" element={<Error404Page />} />
      </Route>
    </>
  )
);

const Pages = () => {
  return <RouterProvider router={router} />;
};

export default Pages;
