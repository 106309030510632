import useFetch from "./useFetch";
import { useCallback, useEffect, useState } from "react";
import { db } from "db";
import { DBStandard } from "db/Standard";

type usePrefetchFarmStandardsProps = {
  enabled?: boolean;
};
export const usePrefetchFarmStandards = ({
  enabled = true,
}: usePrefetchFarmStandardsProps = {}) => {
  const { error, execute, isFetched, isLoading } = useFetch();

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/standards-get");
    db.farmstandards.clear();
    db.farmstandards.bulkPut(data?.d ?? []);
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isFetched,
    isLoading,
    error,
  };
};

const defaultData: DBStandard[] = [];
type useFarmStandardGetManyProps = {
  enabled?: boolean;
  farmGroup: string;
  birdType: string;
  birdSex: string;
};
export const useFarmStandardGetMany = ({
  enabled = true,
  farmGroup,
  birdType,
  birdSex,
}: useFarmStandardGetManyProps) => {
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(defaultData);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await db.farmstandards
        .where("FarmGroup")
        .equalsIgnoreCase(farmGroup)
        .and((x) => x.BirdType.toLowerCase() === birdType.toLowerCase())
        .and((x) => x.BirdSex.toLowerCase() === birdSex.toLowerCase())
        .toArray();

      const data = response ?? defaultData;

      setData(data);

      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [farmGroup, birdType, birdSex]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    error,
    data,
  };
};
