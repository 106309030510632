import * as React from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  type ColumnFiltersState,
  type Header,
  type OnChangeFn,
  type TableMeta,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/placement/DropdownMenu";
import Button from "components/placement/Button";
import {
  ArrowDownIcon,
  ArrowDownUpIcon,
  ArrowUpIcon,
  EyeOffIcon,
} from "lucide-react";
import {
  DataTableToolbar,
  FilterFacet,
  FilterString,
} from "./data-table-toolbar";

export type TableProps = React.HTMLAttributes<HTMLDivElement>;

type DataTableProps<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sorting?: SortingState;
  setSorting?: OnChangeFn<SortingState> | undefined;
  columnFilters?: ColumnFiltersState;
  setColumnFilters?: OnChangeFn<ColumnFiltersState> | undefined;
  tableProps?: TableProps;
  toolbarProps?: {
    string?: FilterString;
    facets?: FilterFacet[];
  };
  tableMeta?: TableMeta<TData>;
};

const DataTable = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & DataTableProps<any, unknown>
>(
  (
    {
      columns,
      data,
      sorting,
      setSorting,
      columnFilters,
      setColumnFilters,
      tableProps,
      toolbarProps,
      tableMeta,
      ...props
    },
    ref
  ) => {
    const table = useReactTable({
      data,
      columns,
      onSortingChange: setSorting,
      onColumnFiltersChange: setColumnFilters,
      state: {
        sorting,
        columnFilters,
      },
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: sorting ? getSortedRowModel() : undefined,
      meta: tableMeta,
    });

    return (
      <>
        <DataTableToolbar table={table} {...toolbarProps} />
        <Table {...tableProps}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return <DataTableHead key={header.id} header={header} />;
                })}
              </TableHeaderRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  }
);

function DataTableHead<TData, TValue>({
  header,
}: {
  header: Header<TData, TValue>;
}) {
  if (header.isPlaceholder) {
    return null;
  }

  const { column } = header;
  const canSort = column.getCanSort();
  const canHide = column.getCanHide();

  return (
    <TableHead>
      <div className="flex items-center space-x-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="text"
              size="sm"
              className="-ml-3 h-8 data-[state=open]:bg-accent"
            >
              <span>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </span>
              {canSort && column.getIsSorted() === "desc" ? (
                <ArrowDownIcon className="ml-2 h-4 w-4" />
              ) : canSort && column.getIsSorted() === "asc" ? (
                <ArrowUpIcon className="ml-2 h-4 w-4" />
              ) : (
                canSort && <ArrowDownUpIcon className="ml-2 h-4 w-4" />
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {canSort && (
              <>
                <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
                  <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                  Asc
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
                  <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                  Desc
                </DropdownMenuItem>
              </>
            )}
            {canSort && canHide && <DropdownMenuSeparator />}
            {canHide && (
              <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
                <EyeOffIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                Hide
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </TableHead>
  );
}

export { DataTable };
export { DataTableFacetedFilter } from "./data-table-faceted-filter";
export type { DataTableProps };
