import type { onSuccessSchema, onErrorSchema } from "./useFormData";
import useFetchWithJS from "./useFetchWithJS";
import useFetchWithMSAL from "./useFetchWithMsal";
import { type RedirectRequest } from "@azure/msal-browser";
import { authConfig } from "authConfig";

type useFetchProps = {
  msalRequest?: RedirectRequest;
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

const useFetch = ({ msalRequest, onSuccess, onError }: useFetchProps = {}) => {
  const msalEnabled = authConfig.mode === "b2c";

  const responseFromJS = useFetchWithJS({ onSuccess, onError });
  const responseFromMSAL = useFetchWithMSAL({
    msalRequest,
    onSuccess,
    onError,
  });

  return msalEnabled ? responseFromMSAL : responseFromJS;
};

export default useFetch;
