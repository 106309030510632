import { cn } from "helpers/common";
import { PlusIcon } from "lucide-react";

export const FieldListHeader = ({
  className,
  title = "Records",
  addButtonTitle = "Add",
  onAddField,
}: {
  className?: string;
  title?: string;
  addButtonTitle?: string;
  onAddField: () => void;
}) => {
  return (
    <div className={cn("relative", className)}>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex items-center justify-between">
        <span className="bg-gray-50 pr-3 text-base font-semibold leading-6 text-gray-900">
          {title}
        </span>
        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onAddField}
        >
          <PlusIcon
            aria-hidden="true"
            className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
          />
          <span>{addButtonTitle}</span>
        </button>
      </div>
    </div>
  );
};
