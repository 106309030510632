import z, { ZodBoolean, ZodDate, ZodNumber, ZodString } from "zod";
import { DynamicFormField } from "./types";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { DateTime } from "luxon";

export const generateZodSchema = (fields: DynamicFormField[]) => {
  const schemaObject: Record<
    string,
    ZodString | ZodNumber | ZodDate | ZodBoolean
  > = {};

  fields.forEach((field) => {
    let fieldSchema: z.ZodTypeAny = z.any();

    const validations = field.validations?.[0]; // Assuming validations is an array

    switch (field.type) {
      case "string":
      case "text": {
        const _fieldSchema = z.string();
        if (validations) {
          if (validations.required) {
            fieldSchema = _fieldSchema.min(
              1,
              validations.errorMessage || `${field.label} is required`
            );
          }
          if (!isNullEmptyOrWhitespace(validations.minLength)) {
            fieldSchema = _fieldSchema.min(
              validations.minLength,
              validations.errorMessage ||
                `${field.label} must be at least ${validations.minLength} characters`
            );
          }
          if (!isNullEmptyOrWhitespace(validations.maxLength)) {
            fieldSchema = _fieldSchema.max(
              validations.maxLength,
              validations.errorMessage ||
                `${field.label} must be at most ${validations.maxLength} characters`
            );
          }
          if (!isNullEmptyOrWhitespace(validations.pattern)) {
            fieldSchema = _fieldSchema.regex(
              new RegExp(validations.pattern),
              validations.errorMessage || `${field.label} is invalid`
            );
          }
        }
        break;
      }
      case "number": {
        const _fieldSchema = z.preprocess((val) => Number(val), z.number());
        if (validations) {
          // if (validations.required) {
          //   // Already required by default
          // } else {
          //   fieldSchema = _fieldSchema.optional();
          // }
          if (!isNullEmptyOrWhitespace(validations.min)) {
            const validationMin = validations.min;
            fieldSchema = _fieldSchema.refine(
              (val) => val >= validationMin,
              validations.errorMessage ||
                `${field.label} must be at least ${validations.min}`
            );
          }
          if (!isNullEmptyOrWhitespace(validations.max)) {
            const validationMax = validations.max;
            fieldSchema = _fieldSchema.refine(
              (val) => val <= validationMax,
              validations.errorMessage ||
                `${field.label} must be at most ${validations.max}`
            );
          }
        }
        break;
      }
      case "date": {
        const _fieldSchema = z
          .string()
          .refine(
            (val) => DateTime.fromISO(val).isValid,
            validations?.errorMessage || `${field.label} is not a valid date`
          );
        if (validations && validations.required) {
          // Already required by default
        } else {
          fieldSchema = _fieldSchema.optional();
        }
        break;
      }
      case "checkbox": {
        const _fieldSchema = z.boolean();
        if (validations && validations.required) {
          fieldSchema = _fieldSchema.refine(
            (val) => val === true,
            validations.errorMessage || `${field.label} is required`
          );
        } else {
          fieldSchema = _fieldSchema.optional();
        }
        break;
      }
      case "select": {
        const _fieldSchema = z.string();
        if (validations && validations.required) {
          fieldSchema = _fieldSchema.min(
            1,
            validations.errorMessage || `${field.label} is required`
          );
        } else {
          fieldSchema = _fieldSchema.optional();
        }
        break;
      }
      default:
        fieldSchema = z.any();
        break;
    }

    schemaObject[field.name] = fieldSchema;
  });

  return z.object({
    rows: z.array(z.object(schemaObject)),
  });
};
