import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { Input } from "components/placement/Input";
import Button from "components/placement/Button";
import { TrashIcon } from "lucide-react";
import Card from "components/Card";
import { Checkbox } from "components/placement/Checkbox";
import { FieldListHeader } from "../../field_list_header";
import { isNullEmptyOrWhitespace, uuid } from "helpers/common";
import { Errors } from "../../editor";
import { DynamicFormBuilderDef } from "../../types";

type FieldValidationsProps = {
  reactForm: UseFormReturn<DynamicFormBuilderDef>;
  fieldIndex: number;
};

const FieldValidations: React.FC<FieldValidationsProps> = ({
  reactForm,
  fieldIndex,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: reactForm.control,
    name: `fields.${fieldIndex}.validations`,
  });

  const handleAddValidation = () => {
    const fieldId = reactForm.getValues(`fields.${fieldIndex}.id`);

    append({
      id: uuid(),
      fieldId,
      required: false,
      minLength: undefined,
      maxLength: undefined,
      pattern: "",
      min: undefined,
      max: undefined,
      validate: "",
      errorMessage: "",
      dependsOn: "",
      equalsValue: "",
    });
  };

  return (
    <div className="space-y-4">
      <FieldListHeader
        title="Field Validations"
        onAddField={handleAddValidation}
      />
      {fields.map((validation, validationIndex) => {
        return (
          <Card key={validation.id} className="p-3 mt-2">
            <div className="flex justify-between items-center">
              <h5 className="text-sm font-medium">
                Validation {validationIndex + 1}
              </h5>
              <Button
                type="button"
                size="icon"
                onClick={() => remove(validationIndex)}
              >
                <TrashIcon className="w-4 h-4" />
                <span className="sr-only">Remove</span>
              </Button>
            </div>
            <Errors
              reactForm={reactForm}
              fieldIndex={fieldIndex}
              validationIndex={validationIndex}
              errorState={
                reactForm.formState.errors?.fields?.[fieldIndex]?.validations?.[
                  validationIndex
                ]
              }
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.required`}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
                    <FormLabel>Required</FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.minLength`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Min Length</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ""} // avoid uncontrolled input warning
                        type="number"
                        placeholder="Minimum Length"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.maxLength`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Max Length</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ""} // avoid uncontrolled input warning
                        type="number"
                        placeholder="Maximum Length"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.pattern`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Pattern</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Regex Pattern" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.min`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Min</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ""} // avoid uncontrolled input warning
                        type="number"
                        placeholder="Minimum"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.max`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Max</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ""} // avoid uncontrolled input warning
                        type="number"
                        placeholder="Maximum"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.validate`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Custom Validation</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Custom Validation Function"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.errorMessage`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Error Message</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Error Message" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.dependsOn`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Depends On</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="-- Select --" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {reactForm
                            .getValues()
                            .fields.filter(
                              (f) =>
                                !isNullEmptyOrWhitespace(f.name) &&
                                f.name !== field.name
                            )
                            .map((field: any) => (
                              <SelectItem key={field.id} value={field.name}>
                                {field.name}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription>
                      Field name this condition depends on
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.validations.${validationIndex}.equalsValue`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Equals Value</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Value to compare" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default FieldValidations;
