/* eslint-disable react-hooks/rules-of-hooks */
import { CellContext } from "@tanstack/react-table";
import React from "react";

export type CustomTableMeta<TData> = {
  updateData: (
    rowIndex: number,
    cellContext: CellContext<TData, unknown>,
    value: unknown
  ) => void;
  getErrorsByCell?: (rowId: string, columnId: string) => string;
  getErrorsByRow?: (rowId: string) => Record<string, string>;
  getIsRowDeleted?: (rowId: string) => boolean;
  isCellChanged?: (rowId: string, columnId: string) => boolean;
  setIsProcessing: (value: boolean) => void;
  skipAutoResetPageIndex: () => void;
};

// IMPORTANT: Already declared in placement planning module
// declare module "@tanstack/react-table" {
//   interface TableMeta<TData extends RowData> extends CustomTableMeta<TData> {}
// }

export function getTableMeta<TData>(
  dbData: TData[],
  data: TData[],
  setData: React.Dispatch<React.SetStateAction<TData[]>>,
  skipAutoResetPageIndex: () => void,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  updateData: (
    updatedRowIndex: number,
    cellContext: CellContext<TData, unknown>,
    value: any
  ) => void,
  resetChanges: () => void
) {
  return {
    updateData,
    resetChanges,
    setIsProcessing,
    skipAutoResetPageIndex,
  };
}
