import React, { useCallback } from "react";
import { onErrorSchema, onSuccessSchema } from "./useFormData";
import useFetch from "./useFetch";
import { protectedResources } from "authConfig";
import { onErrorUpdateMutation, onSuccessfulMutation } from "types";

export type schemaStandard = {
  id: number; // day/week number.
  [key: string]: number | string | undefined;
};

type useConfigStandardsProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

const defaultData: schemaStandard[] = [];

export const useConfigStandards = ({
  onSuccess,
  onError,
}: useConfigStandardsProps) => {
  const { isLoading, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (params: Record<string, unknown>) => {
      const { data } = await execute("POST", "/api/configstandards-post", {
        params,
      });

      const newData = (data?.d ?? defaultData) as schemaStandard[];

      return { data: newData };
    },
    [execute]
  );

  return { isLoading, error, mutate, refetchData: mutate };
};

type useStandardUpdateProps = {
  onSuccess?: (variables?: any) => void;
  onError?: (errMessage: string, variables?: any) => void;
};

export const useStandardUpdate = ({
  onSuccess,
  onError,
}: useStandardUpdateProps) => {
  const { isLoading, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (
      {
        changes,
        farmType,
        birdType,
        frequency,
        fromDate,
      }: {
        changes: { [x: string]: Partial<schemaStandard> };
        farmType?: string;
        birdType?: string;
        frequency?: string;
        fromDate?: string;
      },
      variables?: any
    ) => {
      const { data } = await execute("PUT", "/api/configstandards-put", {
        changes,
        farmType,
        birdType,
        frequency,
        fromDate,
      });

      return data?.d ?? [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [execute]
  );

  return { isLoading, error, mutate };
};

export type standardHeaderSchema = {
  columns: {
    accessorKey: string;
    header: string;
    _filter: string; // used for filtering
  }[];
  birdTypeOpts: {
    label: string;
    value: string;
  }[];
  dateOpts: {
    label: string;
    _filter: string; // used for filtering
  }[];
};

type useStandardHeadersGetManyProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};

const defaultStandardHeaderData: standardHeaderSchema = {
  columns: [],
  birdTypeOpts: [],
  dateOpts: [],
};

export const useStandardHeadersGetMany = ({
  enabled = true,
  onSuccess,
  onError,
}: useStandardHeadersGetManyProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<standardHeaderSchema>(
    defaultStandardHeaderData
  );

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", "/api/configstandardheaders-get");

    setData(data?.d ?? defaultStandardHeaderData);
  }, [execute]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, fetchData]);

  return { data, isLoading, isFetched, error, refetchData: fetchData };
};

export const useStandardDuplicate = ({
  onSuccess,
  onError,
}: useStandardUpdateProps) => {
  const { isLoading, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (
      {
        farmType,
        birdType,
        frequency,
        fromDate,
        newDate,
      }: {
        farmType?: string;
        birdType?: string;
        frequency?: string;
        fromDate?: string;
        newDate?: string;
      },
      variables?: any
    ) => {
      const { data } = await execute(
        "POST",
        "/api/configstandardsduplicate-post",
        {
          farmType,
          birdType,
          frequency,
          fromDate,
          newDate,
        }
      );

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};
