import DynamicForm from "components/dynamic_form";
import PageHeader from "components/PageHeader";
import { isNullEmptyOrWhitespace } from "helpers/common";
import {
  useDynamicFormDataGetOne,
  useDynamicFormDataSave,
  useDynamicFormGetOne,
} from "hooks/useDynamicForm";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SampleDynamicFormPage = () => {
  const navigate = useNavigate();
  const { formId, id } = useParams();

  const {
    data: sampleFormDefinition,
    isLoading: isFormIsLoading,
    isFetched: isFormFetched,
  } = useDynamicFormGetOne({
    enabled: !isNullEmptyOrWhitespace(formId),
    id: formId || "",
  });

  const {
    data: sampleFormData,
    isLoading: isFormDataLoading,
    isFetched: isFormDataFetched,
  } = useDynamicFormDataGetOne({
    enabled: !isNullEmptyOrWhitespace(id) && !isNullEmptyOrWhitespace(formId),
    id: id || "",
    formId: formId || "",
  });

  const { mutate: mutateFormData } = useDynamicFormDataSave({
    onSuccess: async (variables) => {
      toast.success("Changes saved successfully!");

      if (isNullEmptyOrWhitespace(id)) {
        navigate(
          `/sample-dynamic-form/${variables.formId}/edit/${variables.id}`,
          {
            replace: true,
          }
        );
      }
    },
    onError: (errMessage, variables) => {
      toast.error("An error occurred while saving the form data!");
    },
  });

  const isLoading = isFormIsLoading || isFormDataLoading;
  const isFetched =
    isFormFetched && (isFormDataFetched || isNullEmptyOrWhitespace(id));

  return (
    <div className="mt-4 grid grid-cols-2 gap-4 px-4">
      <PageHeader className="col-span-full">
        <div className="flex flex-row justify-between">
          <div className="text-xl">{sampleFormDefinition?.name}</div>
        </div>
      </PageHeader>
      <div className="col-span-full">
        {isLoading && <div>Loading...</div>}
        {isFetched && !sampleFormDefinition && <div>No data found</div>}
        {isFetched && sampleFormDefinition && (
          <DynamicForm
            formDefinition={sampleFormDefinition}
            defaultValues={sampleFormData}
            onCancel={() => navigate(-1)}
            onSave={(data) => {
              mutateFormData(data, { formId: formId, id: id });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SampleDynamicFormPage;
