import { type IUserMsal, type IUser } from "helpers/formUtilities";
import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { UserContext } from "context/UserProvider";
import { deleteCookie, setCookie } from "helpers/storageUtilities";
import { authConfig, protectedResources } from "authConfig";

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a AppDataProvider");
  }

  return context;
};

type useUserGetMeProps = {
  enabled?: boolean;
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserGetMe = ({
  enabled = true,
  onSuccess,
  onError,
}: useUserGetMeProps = {}) => {
  const { isLoading, isFetched, execute, error } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onSuccess: (response, responseBody) => {
      if (authConfig.mode === "b2c") {
        // Do nothing
        return onSuccess?.(response, responseBody);
      } else {
        const user = responseBody?.d;

        if (user) {
          // Renew the cookie
          setCookie("signedIn", "true", { expires: 30 }); // 30 days

          return onSuccess?.(response, responseBody);
        }
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });

  const [data, setData] = useState<IUser | IUserMsal | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userme-get");

    const user = data?.d;

    setData(user);

    return user;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, data, error, fetchData };
};

type useUserSyncADMeProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSyncADMe = ({
  onSuccess,
  onError,
}: useUserSyncADMeProps = {}) => {
  // This is only for MSAL
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: {
      username: string;
      email: string;
      name?: string;
      // customerName?: string;
      // permissionLevel?: number;
      // permissionGroup?: string;
    }) => {
      const { data } = await execute("PUT", "/api/usersync-put", user);
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

const isMsalUser = (user: IUser | IUserMsal): user is IUserMsal => {
  if (authConfig.mode !== "b2c") return false;

  return (user as IUserMsal).id !== undefined;
};
export const isSuperUser = (user: IUser | IUserMsal | undefined) => {
  if (!user) return false;

  if (isMsalUser(user)) {
    return (
      user?.permissionGroupId?.toString() === "0" &&
      user?.permissionLevelId?.toString() === "0"
    );
  }

  return user?.permissionLevelId?.toString() === "0";
};

type useUserGetManyProps = {
  enabled?: boolean;
};
export const useUserGetMany = ({
  enabled = true,
}: useUserGetManyProps = {}) => {
  const [data, setData] = useState<IUser[] | IUserMsal[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/users-get");

    const users = data?.d ?? [];

    setData(users);

    return users;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};
export const useUserGetOneById = ({
  enabled = true,
  id,
}: useUserGetOneByIdProps) => {
  const [data, setData] = useState<IUser | IUserMsal | undefined>(undefined);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", `/api/user-get?id=${id}`);

    const user = data?.d;

    setData(user);

    return user;
  }, [execute, id]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data };
};

type useUserGetOrgLevelsProps = {
  enabled?: boolean;
};
type orgLevelSchema = {
  id: number;
  name: string;
};
export const useUserGetOrgLevels = ({
  enabled = true,
}: useUserGetOrgLevelsProps = {}) => {
  const [data, setData] = useState<orgLevelSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userorglevels-get");

    const orgLevels = data?.d ?? [];

    setData(orgLevels);

    return orgLevels as orgLevelSchema[];
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetPermissionGroupsProps = {
  enabled?: boolean;
};
type permissionGroupSchema = {
  id: number;
  name: string;
  farmSelectionAccess: string;
};
export const useUserGetPermissionGroups = ({
  enabled = true,
}: useUserGetPermissionGroupsProps = {}) => {
  const [data, setData] = useState<permissionGroupSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userpermissiongroups-get");

    const permissionGroups = data?.d ?? [];

    setData(permissionGroups);

    return permissionGroups as permissionGroupSchema[];
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetAssignedFarmsProps = {
  enabled?: boolean;
  id: string;
};
type assignedFarmSchema = {
  farmname: string;
  farmcode: string;
};
export const useUserGetAssignedFarms = ({
  enabled = true,
  id,
}: useUserGetAssignedFarmsProps) => {
  const [data, setData] = useState<assignedFarmSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/userassignedfarms-get?id=${id}`
    );

    const farms = data?.d ?? [];

    setData(farms);

    return farms as assignedFarmSchema[];
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserCreateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserCreate = ({
  onSuccess,
  onError,
}: useUserCreateProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: {
      accountEnabled: boolean;
      displayName: string;
      emailAddress: string;
      username: string;
      forceChangePasswordNextSignIn: boolean; // If set to true it will display "The password has expired."
      dotnetOrganisationLevelId: string;
      dotnetPermissionGroupId: string;
      assignedFarms: string[];
      password: string;
    }) => {
      const { data } = await execute("POST", "/api/user-post", user);
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserUpdateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserUpdate = ({
  onSuccess,
  onError,
}: useUserUpdateProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: {
      id: string; // active directory id
      data: {
        displayName: string;
        emailAddress: string;
        dotnetOrganisationLevelId: string;
        dotnetPermissionGroupId: string;
        assignedFarms?: string[];
        password?: string;
      };
    }) => {
      const { data } = await execute("PUT", "/api/user-put", user);
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserDeleteProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserDelete = ({
  onSuccess,
  onError,
}: useUserDeleteProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (id: string) => {
      const { data } = await execute("DELETE", "/api/user-delete", {
        id,
      });
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserSignInProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSignIn = ({
  onSuccess,
  onError,
}: useUserSignInProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.isAuthenticated) {
        setCookie("signedIn", "true", { expires: 30 }); // 30 days

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });

  const mutate = useCallback(
    async (credentials: { username: string; password: string }) => {
      const { data } = await execute("POST", "/api/signin", {
        ...credentials,
      });

      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useUserSignOutProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSignOut = ({
  onSuccess,
  onError,
}: useUserSignOutProps = {}) => {
  const { execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.status === 200) {
        setUser(undefined);
        deleteCookie("signedIn");

        // clear all session storage
        sessionStorage.clear();

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });
  const { setUser } = useUser();

  const mutate = useCallback(async () => {
    const { data } = await execute("POST", "/api/signout");

    return data;
  }, [execute]);

  return { mutate };
};
