import { Column } from "@tanstack/react-table";
import { Option } from "components/placement/ComboboxPopover";
import { MultiSelect, MultiSelectRef } from "./multi-select";
import React from "react";

type DataTableFacetedFilterProps<TData, TValue> = {
  column?: Column<TData, TValue>;
  title?: string;
  options: Option[];
};

export const DataTableFacetedFilter = React.forwardRef(
  (
    { column, title, options }: DataTableFacetedFilterProps<any, any>,
    ref: React.Ref<MultiSelectRef>
  ) => {
    // const facets = column?.getFacetedUniqueValues();
    const filterValueSet = new Set(column?.getFilterValue() as string[]);
    const selectedValues = filterValueSet.size
      ? Array.from(filterValueSet)
      : [];

    // console.log("facets", facets);
    // console.log("selectedValues", selectedValues);

    return (
      <MultiSelect
        options={options}
        onValueChange={(values) => {
          column?.setFilterValue(values.length ? values : undefined);
        }}
        defaultValue={selectedValues}
        placeholder={`Filter by ${title}`}
        buttonVariant="outline"
        buttonSize="sm"
        badgeVariant="outline"
        badgeSize="sm"
        maxCount={2}
        modalPopover={true}
        ref={ref}
      />
    );
  }
);
