import DynamicTableForm from "components/dynamic_table_form";
import PageHeader from "components/PageHeader";
import { isNullEmptyOrWhitespace } from "helpers/common";
import {
  useDynamicFormDataGetMany,
  useDynamicFormDataSave,
  useDynamicFormGetOne,
} from "hooks/useDynamicForm";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SampleDynamicTableFormPage = () => {
  const navigate = useNavigate();
  const { formId } = useParams();

  const {
    data: sampleFormDefinition,
    isLoading: isFormIsLoading,
    isFetched: isFormFetched,
  } = useDynamicFormGetOne({
    enabled: !isNullEmptyOrWhitespace(formId),
    id: formId || "",
  });

  const {
    data: sampleFormData,
    isLoading: isFormDataLoading,
    isFetched: isFormDataFetched,
  } = useDynamicFormDataGetMany({
    enabled: true,
  });

  const { mutate: mutateFormData } = useDynamicFormDataSave({
    onSuccess: async (variables) => {
      toast.success("Changes saved successfully!");
    },
    onError: (errMessage, variables) => {
      toast.error("An error occurred while saving the form data!");
    },
  });

  const isLoading = isFormIsLoading || isFormDataLoading;
  const isFetched = isFormFetched && isFormDataFetched;

  return (
    <div className="mt-4 grid grid-cols-2 gap-4">
      <PageHeader className="col-span-full">
        <div className="flex flex-row justify-between">
          <div className="text-xl">Sample Maintenance Editor Page</div>
        </div>
      </PageHeader>
      <div className="col-span-full">
        {isLoading && <div>Loading...</div>}
        {isFetched && !sampleFormDefinition && <div>No data found</div>}
        {isFetched && sampleFormDefinition && (
          <DynamicTableForm
            formDefinition={sampleFormDefinition}
            defaultValues={sampleFormData}
            onCancel={() => navigate(-1)}
            onSave={(data) => {
              mutateFormData(data, { formId: formId });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SampleDynamicTableFormPage;
