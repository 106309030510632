import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { X } from "lucide-react";
// import { Button } from "components/core";

type ModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({
  open,
  onOpenChange,
  title,
  children,
  ...props
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/20 fixed inset-0 z-[1000]" />
        <Dialog.Content
          className="z-[1001] fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-md bg-white p-4 shadow-md focus:outline-none"
          onInteractOutside={(ev) => {
            ev.preventDefault();
          }}
        >
          {title ? (
            <Dialog.Title className="m-0 text-lg font-medium pb-2">
              {title}
            </Dialog.Title>
          ) : null}
          <div className="mt-2">{children}</div>
          {/* <div className="mt-2 flex justify-end">
            <Dialog.Close asChild>
              <Button theme="primary" type="button">
                Apply filter
              </Button>
            </Dialog.Close>
          </div> */}
          <Dialog.Close asChild>
            <button
              className="text-gray-400 hover:bg-primary hover:text-white absolute top-2 right-2 inline-flex h-5 w-5 items-center justify-center rounded-full focus:outline-none"
              aria-label="Close"
            >
              <X />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
