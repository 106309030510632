import { isFieldVisible } from "./dynamic_form_util";
import { DynamicFormField } from "./types";

function DynamicFieldVisibilityChecker({
  children,
  field,
  values,
}: {
  children: React.ReactNode;
  field: DynamicFormField;
  values: Record<string, any>;
}) {
  // Check field visibility
  const isVisible = isFieldVisible(field, values);
  if (!isVisible) {
    return null;
  }

  return <>{children}</>;
}

export default DynamicFieldVisibilityChecker;
