/* eslint-disable react-hooks/rules-of-hooks */
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  CellContext,
  Column,
} from "@tanstack/react-table";
import { schemaStandard, useStandardUpdate } from "hooks/useConfigStandards";
import { searchParamsSchema } from "pages/config/standards";
import React, { useImperativeHandle, useState } from "react";
import { getTableMeta } from "./TableMeta";
import useSkipper from "components/placement/useSkipper";
import { toast } from "react-toastify";
import { ListOption } from "components/placement/types";
import { deepClone } from "helpers/common";
import { Loader2Icon } from "lucide-react";

type TableProps = {
  dataIn: schemaStandard[];
  columnsIn: {
    accessorKey: string;
    header: string;
  }[];
  params: searchParamsSchema;
  onProcessing: (isProcessing: boolean) => void;
  onSave?: () => void;
};

export type standardTableRefProps = {
  save: () => void;
};

const Table = React.forwardRef<standardTableRefProps, TableProps>(
  ({ dataIn, columnsIn, params, onProcessing, onSave }, ref) => {
    const columns = React.useMemo(() => {
      let result = columnsIn.map((item) => ({
        accessorKey: item.accessorKey.toString(),
        header: item.header,
      }));

      if (params.frequency.toLocaleUpperCase() === "W") {
        result.unshift({
          accessorKey: "id",
          header: "Week",
        });
      }

      if (params.frequency.toLocaleUpperCase() === "D") {
        result.unshift({
          accessorKey: "id",
          header: "Day",
        });
      }

      return result;
    }, [columnsIn, params.frequency]);

    const [data, setData] = useState(() => [...dataIn]);
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [changes, setChanges] = React.useState<{
      [key: string]: Partial<Omit<schemaStandard, "id">>;
    }>({}); // { rowId: { }}

    const { mutate: mutateStandards } = useStandardUpdate({
      onSuccess: (variables) => {
        toast.success("Changes saved successfully!");
      },
      onError: (errMessage, variables) => {
        console.error(errMessage);
        toast.error("An error occurred while saving the changes!");
      },
    });

    const updateData = async (
      updatedRowIndex: number,
      cellContext: CellContext<schemaStandard, unknown>,
      value: any
    ) => {
      function handleChanges(
        savedRow: schemaStandard,
        unsavedRow: schemaStandard,
        rowUpdates: Partial<schemaStandard>
      ) {
        const updatedRowKey = unsavedRow.id;
        const updatedRow = { ...unsavedRow, ...rowUpdates };

        // Apply the recent changes
        setChanges((prevChanges) => {
          const currentChanges = prevChanges[updatedRowKey] || {};
          let mergedChanges = { ...currentChanges };
          // Iterate only over the properties of updates and compare them with the savedRow
          for (const [propertyKey, newValue] of Object.entries(rowUpdates)) {
            // if (!(propertyKey in savedRow)) continue; // Ensure propertyKey is a key of schemaStandard

            const savedValue = savedRow[propertyKey as keyof schemaStandard];

            // Check if the value has changed, we don't know the type of the value
            if (newValue?.toString() === savedValue?.toString()) {
              // If user reverts the change, remove the property from changes
              delete mergedChanges[
                propertyKey as keyof Omit<schemaStandard, "id">
              ];
            } else {
              // If property is changed, update the property in changes
              (mergedChanges[
                propertyKey as keyof Omit<schemaStandard, "id">
              ] as unknown) = newValue;
            }
          }

          // If there are no changes in mergedChanges, remove the key from prevChanges
          if (Object.keys(mergedChanges).length === 0) {
            const { [updatedRowKey]: _, ...remainingChanges } = prevChanges;
            return remainingChanges;
          }
          return {
            ...prevChanges,
            [updatedRowKey]: mergedChanges,
          };
        });

        return updatedRow;
      }

      setIsProcessing(true);

      // Use setTimeout to break the processing into an asynchronous task,
      // allowing the UI to update before the processing begins
      await new Promise((resolve) => setTimeout(resolve, 0));

      const column = cellContext.column;

      // TODO: this might need moved into the updateEditedRow function??
      const oldValue = data[updatedRowIndex][column.id as keyof schemaStandard];

      // Check if the value has changed, we don't know the type of the value
      if (value?.toString() === oldValue?.toString()) {
        setIsProcessing(false);
        return data;
      }

      // Record the changes to the table
      const tableUpdates: Record<number, Partial<schemaStandard>> = {};

      //#region EDITED CELL: Update the edited row

      setData((prevData) => {
        const newData = updateRowData(
          updatedRowIndex,
          prevData,
          column,
          value,
          tableUpdates
        );

        // Save changes to the table
        for (const [rowIndex, rowUpdate] of Object.entries(tableUpdates)) {
          handleChanges(
            dataIn[parseInt(rowIndex)],
            newData[parseInt(rowIndex)],
            rowUpdate
          );
        }
        return newData;
      });

      setIsProcessing(false);

      skipAutoResetPageIndex();
    };

    const resetChanges = () => {
      setChanges({});
      setData(dataIn);
    };

    const handlePaste = (
      e: React.ClipboardEvent<HTMLInputElement>,
      cellContext: CellContext<any, unknown>
    ) => {
      e.preventDefault();
      const pastedData = e.clipboardData.getData("text");

      // Split pasted data into an array of values (assuming each line represents one value)
      const pastedValues = pastedData
        .split("\n")
        .filter((value) => value.trim() !== "");

      let rowIndex = cellContext.row.index;

      pastedValues.forEach(async (value, index) => {
        // add index parameter
        if (isNaN(Number(value))) {
          value = "0"; // If value is not a number, set it to "0"
        }

        await table.options.meta?.updateData(rowIndex++, cellContext, value);
      });
    };

    useImperativeHandle(ref, () => ({
      async save() {
        if (!table.options.meta) return;

        const farmType: string = params.farmType;
        const birdType: string = params.birdType;
        const frequency: string = params.frequency;
        const fromDate: string = params.fromDate;

        await mutateStandards(
          { changes, farmType, birdType, frequency, fromDate },
          { table }
        );

        onSave?.();
      },
    }));

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      defaultColumn: {
        cell: (cellContext) => {
          const initialValue = cellContext.getValue();
          const [value, setValue] = React.useState(initialValue);

          React.useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          return cellContext.column.id === "id" ? (
            <div>{value as React.ReactNode}</div>
          ) : (
            <input
              title="Double-click to edit"
              className="bg-transparent border-transparent"
              tabIndex={Number(cellContext.column.id)}
              key={cellContext.column.id}
              value={value as string}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Regex to match integers or decimal values
                if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                  setValue(inputValue);
                }
              }}
              onKeyPress={(e) => {
                // Prevent invalid characters from being typed
                if (!/[\d.]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onFocus={() => {
                onProcessing(true);
              }}
              onBlur={(e) => {
                table.options.meta?.updateData(
                  cellContext.row.index,
                  cellContext as CellContext<any, unknown>, // TODO: Fix this type
                  value
                );
                onProcessing(false);
              }}
              onPaste={(e) => handlePaste(e, cellContext)} // Updated here
              style={{ textAlign: "right" }} // Apply right-align style
            />
          );
        },
      },
      autoResetPageIndex,
      meta: getTableMeta<schemaStandard>(
        dataIn,
        data,
        setData,
        skipAutoResetPageIndex,
        setIsProcessing,
        updateData,
        resetChanges
      ) as any, // TODO: Fix this type
    });

    return (
      <>
        <div className="flow-root border bg-white">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle max-h-[800px]">
              <table className="min-w-full divide-y divide-gray-300 ">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className="sticky top-0 z-10 bg-white bg-opacity-40 backdrop-blur backdrop-filter"
                    >
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`text-right ${
                            header.column.id === "id"
                              ? "py-2 px-2 sticky left-0 z-[11] bg-white bg-opacity-40 backdrop-blur backdrop-filter"
                              : ""
                          }`}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="even:bg-gray-50">
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={
                            cell.column.id === "id"
                              ? "py-1 px-2 sticky left-0 z-[1] bg-white bg-opacity-40 backdrop-blur backdrop-filter"
                              : ""
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {isProcessing && (
          <div className="fixed inset-0 bg-black/70 z-50">
            <div className="w-full h-full flex items-center justify-center">
              <Loader2Icon className="w-12 h-12 mr-4 text-primary drop-shadow-md shadow-primary-darkest animate-spin" />
              <div className="text-white drop-shadow-md shadow-primary-darkest">
                <p className="font-mediumA">Processing, please wait...</p>
                <p className="text-xs">
                  Please do not refresh, close, or leave this page.
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export { Table as default };

function updateRowData(
  updatedRowIndex: number,
  data: schemaStandard[],
  column: Column<schemaStandard>,
  rawValue: string | number | ListOption | null | undefined,
  tableUpdates: Record<number, Partial<schemaStandard>> = {}
) {
  // Clone so we can make comparisons
  const newData = deepClone(data) as schemaStandard[];

  // Make direct changes, based on editing mode
  const sanitisedValue = rawValue?.toString().trim();

  // Update edited cell
  const _columnId = column.id as keyof schemaStandard;
  newData[updatedRowIndex][_columnId] = sanitisedValue as never;

  // tableUpdates[updatedRowIndex] = {
  //   ...tableUpdates[updatedRowIndex],
  //   [column.id]: sanitisedValue,
  // };
  tableUpdates[updatedRowIndex] = {
    ...tableUpdates[updatedRowIndex],
    [column.id]: sanitisedValue,
  };

  return newData;
}
