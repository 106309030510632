import Card from "components/Card";
import DynamicChart from "components/Charts/DynamicChart";
import { Alert } from "components/core";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { CHART_TYPE } from "constants.js";
import { extractMetricsFromJsonObjs } from "helpers/dashboardUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useQuery from "hooks/useQuery";
import React, { useEffect, useMemo, useState } from "react";
import DashboardParams from "./DashboardParams";
import Widget from "./Widget";
import { Transition } from "@headlessui/react";
import {
  MessageSquareText,
  ArrowLeftCircle,
  ArrowDown,
  ArrowUp,
  CogIcon,
  SwatchBook,
  Pencil,
  LineChart,
  Trash2,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { EditChartKeyForm } from "./Builder/EditChartKeyForm";
import { IChart, IChartKey, IDashboard, POSITION_DIRECTION } from "./types";
import { EditChartSettingsForm } from "./Builder/EditChartSettingsForm";
import classNames from "classnames";
import EditChartColorsForm from "./Builder/EditChartColorsForm";
import {
  useDashboardGetData,
  useDashboardNoteUpdate,
} from "hooks/useDashboard";
import useNetworkStatus from "hooks/useNetworkStatus";
import { toast } from "react-toastify";

interface DashboardProps {
  dashboard: IDashboard;
  className?: string;
  isEditable?: boolean;
  onEditing?: (isEditing: boolean) => void;
  onAddMetrics?: (chartId: string, chartKeys: IChartKey[]) => void;
  onAddTooltipMetrics?: (chartId: string, chartKeys: IChartKey[]) => void;
  onAddStandardMetrics?: (chartId: string, chartKeys: IChartKey[]) => void;
  onDeleteChart?: (chartId: string) => void;
  onChangeChartPosition?: (
    chartId: string,
    position: POSITION_DIRECTION
  ) => void;
  onChangeSetting?: (chartId: string, key: string, value: any) => void;
  onChangeChartAttr?: (chartId: string, key: string, value: any) => void;
  onChangeChartTitle?: (chartId: string, title: string) => void;
}

function getParamsFromRecord(record: Record<string, unknown>, args: string[]) {
  const params: Record<string, unknown> = {};
  for (const arg of args) {
    params[arg] = record[arg];
  }

  return params;
}

export default function Dashboard({
  dashboard,
  className,
  isEditable = false,
  onEditing,
  onAddMetrics,
  onAddTooltipMetrics,
  onAddStandardMetrics,
  onDeleteChart,
  onChangeChartPosition,
  onChangeSetting,
  onChangeChartAttr,
  onChangeChartTitle,
}: DashboardProps) {
  const [dashboardParams, setDashboardParams] = useState<Record<string, any>>(
    {}
  );
  const { isOnline } = useNetworkStatus();
  const query = useQuery();

  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const navigate = useNavigate();
  const {
    isLoading: isLoadingDashboardData,
    error: errorDashboardData,
    data: dashboardData,
    fetch: refetchDashboardData,
  } = useDashboardGetData({
    enabled: false,
    source: dashboard.dataSources,
    params: {
      farmcode: farmId,
      house: houseId,
    },
  });

  const { mutate: mutateDashboardNote } = useDashboardNoteUpdate({
    onSuccess: () => {
      toast.success("Note saved successfully!");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "An error occurred while saving the note!");
    },
  });

  const {
    // isLoading: isLoadingRelatedData,
    // error: errorRelatedData,
    // data: relatedData,
    fetch: refetchRelatedData,
  } = useDashboardGetData({
    enabled: false,
    source: `${dashboard.dataSources}_daily`,
    params: {
      farmcode: farmId,
      house: houseId,
    },
  });

  const [relatedDashboard, setRelatedDashboard] = useState<any>(undefined);
  const [showRelatedDashboard, setShowRelatedDashboard] =
    useState<boolean>(false);
  const [isEditingChartKeys, setIsEditingChartKeys] = useState<boolean>(false);
  const [isEditingChartTooltipKeys, setIsEditingChartTooltipKeys] =
    useState<boolean>(false);
  const [isEditingChartStandardKeys, setIsEditingChartStandardKeys] =
    useState<boolean>(false);
  const [isEditingChartSettings, setIsEditingChartSettings] =
    useState<boolean>(false);
  const [isEditingChartColors, setIsEditingChartColors] =
    useState<boolean>(false);
  const [selectedChartID, setSelectedChartID] = useState<string | undefined>(
    undefined
  );

  const selectedChart = dashboard.charts.find(
    (c: any) => c.id === selectedChartID
  );

  const abortControllerRef = React.useRef<AbortController | undefined>(
    undefined
  );

  const metrics = useMemo(() => {
    if (!dashboardData) return [];

    return extractMetricsFromJsonObjs(dashboardData.data);
  }, [dashboardData]);

  const standardMetrics = !isNullEmptyOrWhitespace(dashboardData?.standards)
    ? extractMetricsFromJsonObjs(dashboardData.standards)
    : [];

  const sortedCharts = useMemo(() => {
    if (!dashboard?.charts) return [];

    const newDashboard: IDashboard = {
      ...dashboard,
      // Sort by position, desc
      charts: dashboard.charts.sort(
        (a: any, b: any) => a.position - b.position
      ),
    };
    return newDashboard.charts;
  }, [dashboard]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  async function fetchRelatedData(
    title: string,
    record: Record<string, any>,
    args: any
  ) {
    const params = getParamsFromRecord(record, args);

    const data = await refetchRelatedData({ newParams: params });
    // const response = await fetchRelatedTableData(dashboard.dataSources, "daily", record, args);

    const relatedRecordData = data?.data ?? [];
    const relatedStandardData = data?.standardData ?? [];

    const _title = title.replace(
      /\${([a-z0-9_%-+]+)}/g,
      (match, index) => record[index]
    );

    // build out the chart data to help with formatting
    // const chart = {
    //   keys: Object.values(relatedRecordData[0]).map((item: any) => ({
    //     id: item.ID,
    //     title: item.title,
    //   })),
    //   type: CHART_TYPE.TABLE,
    //   settings: {
    //     showConfig: true,
    //   }
    // };

    if (relatedRecordData && relatedStandardData) {
      // const modalContent = (
      //   <>
      //     <div className="text-lg">{_title}</div>
      //     <DynamicChart
      //       chart={chart}
      //       data={relatedRecordData}
      //       standardData={relatedStandardData}
      //     />
      //   </>
      // );

      // setShowModal(true, modalContent);

      const newRelatedDashboard = {
        title: _title,
        charts: [
          {
            id: `related-${record.id}`,
            keys: Object.values(relatedRecordData[0]).map((item: any) => ({
              id: item.ID,
              title: item.title,
            })),
            type: CHART_TYPE.TABLE,
            settings: {
              showConfig: true,
            },
          },
        ],
        data: relatedRecordData,
        standardData: relatedStandardData,
      };

      setRelatedDashboard(newRelatedDashboard);
      setShowRelatedDashboard(true);
    }

    // setRelatedData({ [record.id]: {
    //   chart,
    //   data: relatedRecordData,
    //   standardData: relatedStandardData,
    // } });

    // if (chart && relatedRecordData && relatedStandardData) {
    //   setModalContent(<DynamicChart
    //     chart={chart}
    //     data={relatedRecordData}
    //     standardData={relatedStandardData}
    //   />)

    //   setShowModal(true);
    // }
  }

  function onClickToggleEditing(
    formName?:
      | "chartKeys"
      | "standardKeys"
      | "tooltipKeys"
      | "chartSettings"
      | "chartColors",
    chartId?: string
  ) {
    if (formName === "chartKeys") {
      setIsEditingChartKeys(true);
      setIsEditingChartSettings(false);
      setIsEditingChartColors(false);
      setIsEditingChartStandardKeys(false);
      setIsEditingChartTooltipKeys(false);
      setSelectedChartID(chartId);
      onEditing && onEditing(true);
    } else if (formName === "standardKeys") {
      setIsEditingChartKeys(false);
      setIsEditingChartSettings(false);
      setIsEditingChartColors(false);
      setIsEditingChartStandardKeys(true);
      setIsEditingChartTooltipKeys(false);
      setSelectedChartID(chartId);
      onEditing && onEditing(true);
    } else if (formName === "tooltipKeys") {
      setIsEditingChartKeys(false);
      setIsEditingChartSettings(false);
      setIsEditingChartColors(false);
      setIsEditingChartStandardKeys(false);
      setIsEditingChartTooltipKeys(true);
      setSelectedChartID(chartId);
      onEditing && onEditing(true);
    } else if (formName === "chartSettings") {
      setIsEditingChartKeys(false);
      setIsEditingChartSettings(true);
      setIsEditingChartColors(false);
      setIsEditingChartStandardKeys(false);
      setIsEditingChartTooltipKeys(false);
      setSelectedChartID(chartId);
      onEditing && onEditing(true);
    } else if (formName === "chartColors") {
      setIsEditingChartKeys(false);
      setIsEditingChartSettings(false);
      setIsEditingChartColors(true);
      setIsEditingChartStandardKeys(false);
      setIsEditingChartTooltipKeys(false);
      setSelectedChartID(chartId);
      onEditing && onEditing(true);
    } else {
      setIsEditingChartKeys(false);
      setIsEditingChartSettings(false);
      setIsEditingChartColors(false);
      setIsEditingChartStandardKeys(false);
      setIsEditingChartTooltipKeys(false);
      setSelectedChartID(undefined);
      onEditing && onEditing(false);
    }
  }

  function navigateTo(url: string, record: Record<string, any>, args: any) {
    const _url = url.replace(
      /\${([a-z0-9_%-+]+)}/g,
      (match, index) => record[index]
    );

    return navigate(_url);
  }

  function saveChartNote(chartId: string, data: any) {
    mutateDashboardNote({
      chartId,
      farmcode: dashboardParams.FarmCode,
      house: dashboardParams.House,
      cropdate: dashboardParams.CropDate,
      note: data,
    });
  }

  // Used to pass functions to the DynamicChart component
  // for use in the event system
  const eventFunctions = {
    fetchRelated: fetchRelatedData,
    navigateTo: navigateTo,
  };

  if (dashboard === undefined) {
    return null;
  }

  return (
    <div className="grid gap-4 grid-cols-12">
      <Transition
        show={!!dashboard && !showRelatedDashboard}
        unmount={false}
        className="col-span-full"
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
        enterTo="opacity-100 translate-y-0 tablet:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
        leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
      >
        <DashboardParams
          key={`dashboard-params-${dashboard.id}`} // Force re-render when dashboard changes
          wrapper={DashboardParamsWrapper}
          dashboard={dashboard}
          onSubmit={(params) => {
            setDashboardParams(params);
            return refetchDashboardData({ newParams: params });
          }}
        />
      </Transition>

      {isLoadingDashboardData ? (
        <div className="col-span-full">
          <ListSkeleton />
        </div>
      ) : errorDashboardData ? (
        <div className="col-span-full">
          <Alert theme="danger" size="sm">
            {errorDashboardData}
          </Alert>
        </div>
      ) : dashboardData?.data?.length < 1 ? (
        <div className="col-span-full">
          <p className="text-xs text-center">
            No records found. Try refining your search params.
          </p>
        </div>
      ) : (
        <>
          {!isOnline && (
            <div className="col-span-full">
              <Alert theme="warning" className="mb-6" size="sm">
                Offline: you are currently offline. Dashboard data may appear
                out of date.
              </Alert>
            </div>
          )}

          <Transition
            show={!showRelatedDashboard}
            className="col-span-full grid gap-4 grid-cols-12"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            enterTo="opacity-100 translate-y-0 tablet:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
            leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          >
            {sortedCharts.map((chart: IChart) => {
              if (chart?.id === undefined) {
                return null;
              }

              const isEditingThisChart =
                selectedChart !== undefined &&
                selectedChart.id === chart.id &&
                (isEditingChartKeys ||
                  isEditingChartSettings ||
                  isEditingChartColors ||
                  isEditingChartStandardKeys ||
                  isEditingChartTooltipKeys);

              const chartTitle =
                isEditingThisChart && isEditingChartKeys
                  ? `Editing '${chart.title}' chart keys`
                  : isEditingThisChart && isEditingChartSettings
                  ? `Editing '${chart.title}' chart settings`
                  : chart.title;

              return (
                <Widget
                  key={`widget_${chart.id}`}
                  title={chartTitle}
                  className={"relative"}
                  showTitle={chart.settings?.showTitle}
                  size={chart?.settings?.widgetSize}
                >
                  {isEditable ? (
                    <div className="inline-flex space-x-4">
                      <div
                        className={classNames(
                          isEditingThisChart && isEditingChartKeys
                            ? "text-primary"
                            : "text-gray-300",
                          "hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                        )}
                        onClick={() => {
                          if (isEditingThisChart && isEditingChartKeys) {
                            onClickToggleEditing();
                          } else {
                            onClickToggleEditing("chartKeys", chart.id);
                          }
                        }}
                        title="Edit chart keys"
                      >
                        <Pencil className="w-4 h-4" />
                        {isEditingThisChart && isEditingChartKeys ? (
                          <span>Cancel</span>
                        ) : null}
                      </div>

                      {standardMetrics?.length > 0 &&
                      (
                        [CHART_TYPE.SCATTER, CHART_TYPE.LINE] as string[]
                      ).includes(chart.type) ? (
                        <div
                          className={classNames(
                            isEditingThisChart && isEditingChartStandardKeys
                              ? "text-primary"
                              : "text-gray-300",
                            "hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                          )}
                          onClick={() => {
                            if (
                              isEditingThisChart &&
                              isEditingChartStandardKeys
                            ) {
                              onClickToggleEditing();
                            } else {
                              onClickToggleEditing("standardKeys", chart.id);
                            }
                          }}
                          title="Edit standard keys"
                        >
                          <LineChart className="w-4 h-4" />
                          {isEditingThisChart && isEditingChartStandardKeys ? (
                            <span>Cancel</span>
                          ) : null}
                        </div>
                      ) : null}

                      {(
                        [
                          CHART_TYPE.SCATTER,
                          CHART_TYPE.LINE,
                          CHART_TYPE.BAR,
                        ] as string[]
                      ).includes(chart.type) ? (
                        <div
                          className={classNames(
                            isEditingThisChart && isEditingChartTooltipKeys
                              ? "text-primary"
                              : "text-gray-300",
                            "hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                          )}
                          onClick={() => {
                            if (
                              isEditingThisChart &&
                              isEditingChartTooltipKeys
                            ) {
                              onClickToggleEditing();
                            } else {
                              onClickToggleEditing("tooltipKeys", chart.id);
                            }
                          }}
                          title="Edit tooltip keys"
                        >
                          <MessageSquareText className="w-4 h-4" />
                          {isEditingThisChart && isEditingChartTooltipKeys ? (
                            <span>Cancel</span>
                          ) : null}
                        </div>
                      ) : null}

                      {[
                        CHART_TYPE.BAR.toString(),
                        CHART_TYPE.LINE.toString(),
                        CHART_TYPE.SCATTER.toString(),
                      ].includes(chart.type) && (
                        <div
                          className={classNames(
                            isEditingThisChart && isEditingChartColors
                              ? "text-primary"
                              : "text-gray-300",
                            "hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                          )}
                          onClick={() => {
                            if (isEditingThisChart && isEditingChartColors) {
                              onClickToggleEditing();
                            } else {
                              onClickToggleEditing("chartColors", chart.id);
                            }
                          }}
                          title="Edit colors"
                        >
                          <SwatchBook className="w-4 h-4" />
                          {isEditingThisChart && isEditingChartColors ? (
                            <span>Cancel</span>
                          ) : null}
                        </div>
                      )}

                      <div
                        className={classNames(
                          isEditingThisChart && isEditingChartSettings
                            ? "text-primary"
                            : "text-gray-300",
                          "hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                        )}
                        onClick={() => {
                          if (isEditingThisChart && isEditingChartSettings) {
                            onClickToggleEditing();
                          } else {
                            onClickToggleEditing("chartSettings", chart.id);
                          }
                        }}
                        title="Edit chart settings"
                      >
                        <CogIcon className="w-4 h-4" />
                        {isEditingThisChart && isEditingChartSettings ? (
                          <span>Cancel</span>
                        ) : null}
                      </div>

                      <div
                        className={classNames(
                          "text-gray-300 hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                        )}
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you wish to delete the ${chart.title} chart?`
                            )
                          )
                            onDeleteChart && onDeleteChart(chart.id);
                        }}
                        title="Delete chart"
                      >
                        <Trash2 className="w-4 h-4" />
                      </div>

                      <div
                        className="text-gray-300 hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                        onClick={() => {
                          onChangeChartPosition &&
                            onChangeChartPosition(
                              chart.id,
                              POSITION_DIRECTION.UP
                            );
                        }}
                        title="Move chart up"
                      >
                        <ArrowUp className="w-4 h-4" />
                      </div>

                      <div
                        className="text-gray-300 hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                        onClick={() => {
                          onChangeChartPosition &&
                            onChangeChartPosition(
                              chart.id,
                              POSITION_DIRECTION.DOWN
                            );
                        }}
                        title="Move chart down"
                      >
                        <ArrowDown className="w-4 h-4" />
                      </div>
                    </div>
                  ) : null}

                  <Transition
                    show={!isEditingThisChart}
                    className="relative"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                    enterTo="opacity-100 translate-y-0 tablet:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
                    leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                  >
                    <DynamicChart
                      key={chart.id}
                      chart={chart}
                      data={dashboardData.data}
                      standardData={dashboardData.standards}
                      notes={dashboardData.notes}
                      eventFunctions={eventFunctions as any}
                      save={
                        chart.type === CHART_TYPE.NOTE
                          ? saveChartNote
                          : undefined
                      }
                    />
                  </Transition>

                  <Transition
                    show={isEditingThisChart && isEditingChartKeys}
                    className=""
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                    enterTo="opacity-100 translate-y-0 tablet:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
                    leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                  >
                    {selectedChart ? (
                      <EditChartKeyForm
                        chart={chart}
                        selectedChartKeys={selectedChart.keys}
                        metrics={metrics}
                        isComparisonAllowed={true}
                        isEventsAllowed={true}
                        onClickSave={(chartKeys) => {
                          onAddMetrics &&
                            onAddMetrics(selectedChart.id, chartKeys);
                          onClickToggleEditing();
                        }}
                      />
                    ) : (
                      <Alert theme="warning" className="mb-6" size="sm">
                        No chart selected
                      </Alert>
                    )}
                  </Transition>

                  <Transition
                    show={isEditingThisChart && isEditingChartStandardKeys}
                    className=""
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                    enterTo="opacity-100 translate-y-0 tablet:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
                    leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                  >
                    {selectedChart ? (
                      <EditChartKeyForm
                        chart={chart}
                        selectedChartKeys={selectedChart.standardKeys}
                        metrics={standardMetrics}
                        isComparisonAllowed={false}
                        isEventsAllowed={false}
                        onClickSave={(standardKeys) => {
                          onAddStandardMetrics &&
                            onAddStandardMetrics(
                              selectedChart.id,
                              standardKeys
                            );
                          onClickToggleEditing();
                        }}
                      />
                    ) : (
                      <Alert theme="warning" className="mb-6" size="sm">
                        No chart selected
                      </Alert>
                    )}
                  </Transition>

                  <Transition
                    show={isEditingThisChart && isEditingChartTooltipKeys}
                    className=""
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                    enterTo="opacity-100 translate-y-0 tablet:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
                    leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                  >
                    {selectedChart ? (
                      <EditChartKeyForm
                        chart={chart}
                        selectedChartKeys={selectedChart.tooltipKeys}
                        metrics={metrics}
                        isComparisonAllowed={false}
                        isEventsAllowed={false}
                        onClickSave={(tooltipKeys) => {
                          onAddTooltipMetrics &&
                            onAddTooltipMetrics(selectedChart.id, tooltipKeys);
                          onClickToggleEditing();
                        }}
                      />
                    ) : (
                      <Alert theme="warning" className="mb-6" size="sm">
                        No chart selected
                      </Alert>
                    )}
                  </Transition>

                  <Transition
                    show={isEditingThisChart && isEditingChartSettings}
                    className="col-span-full"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                    enterTo="opacity-100 translate-y-0 tablet:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
                    leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                  >
                    {selectedChart ? (
                      <EditChartSettingsForm
                        chart={selectedChart}
                        onChangeTitle={(value) => {
                          onChangeChartTitle &&
                            onChangeChartTitle(selectedChart.id, value);
                        }}
                        onClickSave={() => {
                          onClickToggleEditing();
                        }}
                        onChangeSettings={(key, value) => {
                          onChangeSetting &&
                            onChangeSetting(selectedChart.id, key, value);
                        }}
                        onChangeAttr={(key, value) => {
                          onChangeChartAttr &&
                            onChangeChartAttr(selectedChart.id, key, value);
                        }}
                      />
                    ) : (
                      <Alert theme="warning" className="mb-6" size="sm">
                        No chart selected
                      </Alert>
                    )}
                  </Transition>

                  <Transition
                    show={isEditingThisChart && isEditingChartColors}
                    className="col-span-full"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                    enterTo="opacity-100 translate-y-0 tablet:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
                    leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
                  >
                    {selectedChart ? (
                      <EditChartColorsForm
                        chart={selectedChart}
                        onClickSave={() => {
                          onClickToggleEditing();
                        }}
                        onChangeSettings={(key, value) => {
                          onChangeSetting &&
                            onChangeSetting(selectedChart.id, key, value);
                        }}
                      />
                    ) : (
                      <Alert theme="warning" className="mb-6" size="sm">
                        No chart selected
                      </Alert>
                    )}
                  </Transition>
                </Widget>
              );
            })}
          </Transition>

          <Transition
            show={showRelatedDashboard}
            className="col-span-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            enterTo="opacity-100 translate-y-0 tablet:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
            leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          >
            <div
              className="inline-flex items-center text-gray-400 hover:cursor-pointer hover:text-primary"
              onClick={() => setShowRelatedDashboard(false)}
            >
              <ArrowLeftCircle className="h-6 w-6 mr-2" />
              Back to parent dashboard
            </div>
            <div className="text-2xl font-bold mb-4">
              {relatedDashboard?.title}
            </div>
            {relatedDashboard?.charts?.map((chart: any) => (
              <Widget
                key={`widget_${chart.id}`}
                title={chart.title}
                className={"relative"}
                showTitle={chart.settings?.showTitle}
                size={chart?.settings?.widgetSize}
              >
                <DynamicChart
                  key={chart.id}
                  chart={chart}
                  data={relatedDashboard.data}
                  standardData={relatedDashboard.standardData}
                  notes={dashboardData.notes}
                  save={
                    chart.type === CHART_TYPE.NOTE ? saveChartNote : undefined
                  }
                />
              </Widget>
            ))}
          </Transition>
        </>
      )}
    </div>
  );
}

interface DashboardParamsWrapperProps {
  children: React.ReactNode;
}

function DashboardParamsWrapper({ children }: DashboardParamsWrapperProps) {
  return (
    <Card>
      <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-4">
        {children}
      </div>
    </Card>
  );
}
