import {
  convertNullOrUndefinedStringToEmptyString,
  isNullEmptyOrWhitespace,
} from "./stringUtilities";
import { round, roundNaturally } from "helpers/mathUtilities";
import jsonLogic from "json-logic-js";
import { dateToString } from "./dateUtilities";
import {
  isFieldInCalc,
  createFormFieldLookupKey,
  getPenDataFromFormData,
  parseCustomLogic,
} from "./formUtilities";
import { getFarmHousePen } from "./farmUtilities";
import { deepClone } from "./dataUtilities";

/**
 * Recursively build form values.
 * @param {object} field
 * @param {string|number} pen
 * @param {any} formValue
 * @returns {{ PenValues: [{ Values: [{ Ref: string, Value: string|number, Pen: string|number }]}]}}
 */
export function buildFormValues(
  field,
  penId,
  formValue,
  form,
  dataSources,
  farm,
  house,
  birdAge,
  formDate,
  previouslyChanged = undefined // Store changed dependencies to avoid infinite loop
) {
  const thisFormValues = dataSources?.this ?? null;
  const prevFormValues = dataSources?.previous ?? null;
  const normalisedRef = field.Ref.toLowerCase();
  const normalisedQuestionGroup = field.QuestionGroup?.toLowerCase();
  const formValueRef = formValue.Ref; // Form value ref can differ from field ref (e.g. when using repeater fields `field.Ref_1`)
  const repeaterIndex = !isNullEmptyOrWhitespace(field.RepeaterID)
    ? formValueRef?.split("_")[1] ?? 1
    : null;

  // Value already set, prevent infinite loop
  // const existingFormValue = getExistingFormValue();
  // console.log("existingFormValue", normalisedRef, existingFormValue === value);
  // if (existingFormValue === value) return thisFormValues; // Do nothing
  // console.log("existingFormValue", normalisedRef, existingFormValue);

  let newFormValues = deepClone(thisFormValues);

  if (!previouslyChanged) previouslyChanged = new Set();

  // Avoid infinite loop
  const previouslyChangedLookupKey = createFormFieldLookupKey(
    formValueRef,
    normalisedQuestionGroup
  );
  previouslyChanged.add(previouslyChangedLookupKey);

  // Format form values to pass to jsonLogic
  const penValuesAsObj = formValuesToObject(thisFormValues?.PenValues);
  const prevFormValuesAsObj = formValuesToObject(prevFormValues?.PenValues);

  // Parse value for calculations
  // test for JSON conditional logic
  const isUploadFormValue =
    typeof formValue?.Value === "object" &&
    (formValue?.Value?.hasOwnProperty("pending") ||
      formValue?.Value?.hasOwnProperty("saved") ||
      formValue?.Value?.hasOwnProperty("deleting"));
  let result = formValue.Value;
  if (!isUploadFormValue) {
    result = parseJsonLogic(result, {
      waterMeterType: house?.WaterMeterType,
      this: result,
      previous: prevFormValuesAsObj,
      ...penValuesAsObj,
    });
  }

  const isStringFunc = /(?:join\((?:[^()]+)\))/i.test(result);
  const isDateFunc = /\${date(?:[^{}]+)}|\${_datetoday}/i.test(result);
  const isCalculation =
    !isStringFunc && !isDateFunc && /\${(?:[^{}]+)}/i.test(result);
  // const parsedValue = parseCalculationString(
  //   result,
  //   newFormValues,
  //   prevFormValues,
  //   standards,
  //   farm,
  //   house,
  //   penId,
  //   birdAge,
  //   formDate
  // );
  const thisFarmHousePen = getFarmHousePen(house, penId);
  const thisPlacement = thisFarmHousePen?.Placement ?? null;
  const params = {
    Ref: formValueRef,
    RepeaterIndex: repeaterIndex,
    ListOptions: field?.ListOptions,
    PenId: penId?.toString(),
    GroupId: normalisedQuestionGroup,
    BirdType: thisPlacement?.BirdType ?? null,
    BirdSex: thisPlacement?.BirdSex ?? null,
    BirdAge: { Days: birdAge ?? null, Weeks: null },
    HatchDate: thisPlacement?._HatchDate ?? null,
    FarmGroup: farm?.FarmGroup ?? null,
    FormDate: formDate,
  };
  let parsedValue = "";
  if (!isNullEmptyOrWhitespace(result)) {
    // add this form value to data sources
    parsedValue = parseCustomLogic(result, dataSources, params);
  }

  // Uncomment to debug
  // prettier-ignore
  // console.log("buildFormValues", "Ref:", formValueRef, "parsedValue:", parsedValue, "Group:", normalisedQuestionGroup, "calculation:", result, "dataSources:", dataSources, "isCalculation", isCalculation);

  let evalValue;
  if (isCalculation) {
    try {
      // eslint-disable-next-line no-eval
      evalValue = eval(parsedValue);
    } catch (err) {
      evalValue = parsedValue;
    }
  } else {
    // we keep undefined || null as a string above to allow eval to perform inline conditionals
    // however we need to convert back to undefined || null here
    evalValue = convertNullOrUndefinedStringToEmptyString(parsedValue);
  }

  // Format value
  // Form field doesn't exist we just completely ignore...
  if (!field?.FieldType) return newFormValues;

  const formattedValue = formatValue(evalValue, field.FieldType);
  // prettier-ignore
  // console.log("buildFormValues", "Ref:", formValueRef, "Group:", normalisedQuestionGroup, "result:", evalValue);

  // additional form related properties
  const other = {};

  if (field?.QuestionGroup) {
    // QuestionGroup
    other.QuestionGroup = normalisedQuestionGroup;
  }

  // Map additional listOption properties to formValue
  const listOption = field.ListOptions?.find(
    (lo) => formattedValue?.toString() === lo.Value?.toString()
  );

  if (listOption !== undefined) {
    const _score = listOption?.Score;
    if (!isNullEmptyOrWhitespace(_score)) other.Score = _score;

    const _text = listOption?.Text;
    if (!isNullEmptyOrWhitespace(_text)) other.Text = _text;

    const _days = listOption?.Days;
    if (!isNullEmptyOrWhitespace(_days)) other.Days = _days;

    const _owner = listOption?.Owner;
    if (!isNullEmptyOrWhitespace(_owner)) other.Owner = _owner;
  }

  /**
   * Repeater
   * Not originally supplied by the backend in formvalues-get. It's main use
   * is to identify the repeater field when updating the formValues in the backend.
   */

  if (!isNullEmptyOrWhitespace(field.RepeaterID)) {
    other.RepeaterID = field.RepeaterID;
  }

  const pen = getPenDataFromFormData(penId, newFormValues);
  const newFormValue = {
    ...formValue,
    ...other,
    Ref: formValue.Ref, // We use the formValue.Ref here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    Value: formattedValue,
  };

  if (!isNullEmptyOrWhitespace(formattedValue) && pen === undefined) {
    // Only add if contains a value
    // Pen doesn't exist in formValues... add it
    if (newFormValues?.PenValues === undefined) {
      newFormValues.PenValues = [];
    }

    // New pen
    newFormValues.PenValues.push({
      Pen: penId,
      Values: [newFormValue],
    });
  } else if (pen !== undefined) {
    // Existing pen
    // Remove existing value from formValues array
    const newValues = removeFormValueFromPenData(pen, {
      Ref: formValueRef,
      QuestionGroup: normalisedQuestionGroup,
      // RepeaterID: formValue.RepeaterID,
    });

    if (!isNullEmptyOrWhitespace(formattedValue)) {
      // Only add if contains a value
      // Add new value
      newValues.push(newFormValue);
    }

    pen.Values = newValues;
  }

  // Calculations
  // find all form fields that contain this ref in their Calculation
  /// and add to list of dependencies
  const calcDependencies = form.FormFields.filter((ff) => {
    const isSelf =
      ff.Ref.toLowerCase() === normalisedRef &&
      (isNullEmptyOrWhitespace(normalisedQuestionGroup) ||
        ff.QuestionGroup?.toLowerCase() === normalisedQuestionGroup);
    if (isSelf) {
      // Avoid changing self
      // Avoid changing previously changed fields
      return false;
    }

    const previouslyChangedLookupKey = createFormFieldLookupKey(
      ff.Ref,
      ff.QuestionGroup
    );
    const wasPreviouslyChanged = previouslyChanged.has(
      previouslyChangedLookupKey
    );
    if (wasPreviouslyChanged) {
      // Avoid changing previously changed fields
      return false;
    }

    // prettier-ignore
    // console.log("calcDependencies", "Ref:", ff.Ref, "Group:", ff.QuestionGroup, "Calc:", ff.Calculation, "containsRef:", isFieldInCalc({ ref: normalisedRef, group: normalisedQuestionGroup, dataSource: "this" }, ff.Calculation, { ...params, Ref: ff.Ref, GroupId: ff.QuestionGroup }));

    const hasCalculation =
      ff.Calculation?.includes("house:birdsalive") || // anything with house:birdsalive
      ff.Calculation?.includes("pen:birdsalive") || // anything with pen:birdsalive
      isFieldInCalc({ ref: normalisedRef, group: normalisedQuestionGroup, dataSource: "this" }, ff.Calculation, { ...params, Ref: ff.Ref, GroupId: ff.QuestionGroup });

    if (hasCalculation) {
      // is calculation
      return true;
    }

    return false;
  });

  // console.log(`calcDependencies for ${formValueRef}`, calcDependencies);

  calcDependencies.forEach((cd) => {
    // cd.Calculation could be a JSON object or string
    let calculations;
    try {
      // JSON string
      calculations = JSON.parse(cd.Calculation);
    } catch {
      // String
      calculations = cd.Calculation;
    }

    // Ensure it's an array
    if (!(calculations instanceof Array)) calculations = [calculations];

    // Iterate through form field calculations array
    calculations.forEach((calculation) => {
      const isRepeater = !isNullEmptyOrWhitespace(cd.RepeaterID);
      const refWithRepeater = isRepeater
        ? `${cd.Ref}_${repeaterIndex}`
        : cd.Ref;
      const cdFormValue = {
        /**
         * If the calculation is a repeater field, we need to add the repeater index to the Ref.
         * The Repeater index is the index of the repeater index of the field that triggered the buildFormValues.
         */
        Ref: refWithRepeater,
        QuestionGroup: cd.QuestionGroup,
        Value: calculation,
      };

      const cdDataSources = {
        ...dataSources,
        this: newFormValues,
      };

      // Now replace calc dependency values with eval(parseCalc)
      newFormValues = buildFormValues(
        cd,
        cd.Level.toLowerCase() === "h" ? 1 : penId,
        // eslint-disable-next-line no-eval
        cdFormValue,
        form,
        cdDataSources,
        farm,
        house,
        birdAge,
        formDate,
        previouslyChanged
      );
    });
  });

  return newFormValues;
}

/**
 * Convert form values to an Object.
 * @param {import("./formUtilities").IPenData[]} formValues
 * @returns {{ String: { String: { Ref: string, Value: String|Number } } }}
 */
export function formValuesToObject(formValues) {
  if (isNullEmptyOrWhitespace(formValues)) return {};

  return formValues.reduce((result, pen) => {
    result[`pen${pen.Pen}`] = pen.Values.reduce((result2, pv) => {
      result2[pv.Ref] = pv;

      return result2;
    }, {});

    return result;
  }, {});
}

/**
 * Parse JSON logic
 * @param {*} value
 * @param {*} data
 * @returns
 */
export function parseJsonLogic(value, data) {
  return jsonLogic.apply(value, { ...data });
}

/**
 * Format value
 * @param {*} value
 * @param {*} fieldType
 * @returns
 */
const formatValue = (value, fieldType) => {
  if (value === null || value === undefined || !value?.toString()?.length)
    return value;

  // Calculated fields are deliberately not rounded up to prevent changing DB values unintentionally.
  fieldType = fieldType?.toLowerCase();
  switch (fieldType) {
    case "dp":
      value = value.toString();
      break;
    case "i":
      value = roundNaturally(value, 0);
      break;
    case "cfr":
      value = round(value);
      break;
    case "fl":
      value = roundNaturally(value, 3);
      break;
    default:
      // TODO: this should be removed as the value is expected to be a string
      value = roundNaturally(value, 2);
      break;
  }

  return value;
};

export function getReadonlyFormValueByFieldType(field, formValue) {
  if (isNullEmptyOrWhitespace(formValue)) return formValue;

  const _fieldType = field.FieldType?.toLowerCase();
  // Here we decide how to render the parent fields
  // Change field type of current fields to 'tx'
  switch (_fieldType) {
    case "h":
      return undefined;
    case "dp": // Datepicker
    case "rdp": // Restricted Datetimepicker
      return dateToString(formValue.Value, { includeTime: false });
    case "dtp": // Datetimepicker
      return dateToString(formValue.Value, { includeTime: true });
    case "ac": // Autocomplete
    case "up": // Upload
      if (typeof formValue.Value === "string") {
        return formValue.Value?.split(",")?.filter(Boolean) ?? [];
      }
      return [];
    case "mdd": // Multi-select Dropdown
      const csvValues = formValue.Value?.split(",");
      let mddValues = [];
      if (csvValues?.length) {
        for (const value of csvValues) {
          const mddValue = field.ListOptions?.find(
            (o) => o.Value === value
          )?.Text;
          if (mddValue) mddValues.push(mddValue);
        }
      }

      return mddValues.join(", ");
    case "cb": // Checkbox
    case "dd": // Dropdown
    case "bg": // Button Group
      const _text = field.ListOptions?.find(
        (o) => o.Value === formValue.Value
      )?.Text;
      return _text ? _text : formValue.Value;
    case "sl": // Comment
    case "t":
    case "tx": // HTML text
    case "cf": // Calculated field
    case "cfr": // Calculated field
    case "f": // Float
    case "i": // Integer
    default:
      return formValue.Value ?? "";
  }
}

export function getPWAIDfromFormValuesRecord(record) {
  if (record === undefined || record.PenValues === undefined) return undefined;

  // We currently assume that pen 1 always exists and has the pwa id
  const pen1 = record.PenValues.find((pv) => pv.Pen.toString() === "1");
  if (pen1 === undefined) {
    return undefined;
  }

  const pwaid = pen1.Values?.find(
    (pv) => pv.Ref.toLowerCase() === "pwaid"
  )?.Value;

  return pwaid ?? null;
}

export function removeFormValueFromPenData(penData, formValue) {
  const formValueIndex = penData.Values.findIndex((fv) => {
    if (fv.Ref.toLowerCase() !== formValue.Ref.toLowerCase()) return false;

    const hasQuestionGroup = !isNullEmptyOrWhitespace(
      formValue.QuestionGroup?.toLowerCase()
    );
    /*const hasRepeaterID = !isNullEmptyOrWhitespace(formValue.RepeaterID);
    if (hasQuestionGroup && hasRepeaterID) {
      // Question group && Repeater field
      const hasMatchingQuestionGroup =
        fv.QuestionGroup?.toLowerCase() ===
        formValue.QuestionGroup?.toLowerCase();
      const hasMatchingRepeaterID = fv.RepeaterID === formValue.RepeaterID;
      if (!hasMatchingQuestionGroup || !hasMatchingRepeaterID) {
        // Doesn't match question group or repeater ID
        return false;
      }
    } else*/ if (hasQuestionGroup) {
      // Question group field
      const hasMatchingQuestionGroup =
        fv.QuestionGroup?.toLowerCase() ===
        formValue.QuestionGroup?.toLowerCase();
      if (!hasMatchingQuestionGroup) {
        // Doesn't match question group
        return false;
      }
    } /*else if (hasRepeaterID) {
      // Repeater field
      const hasMatchingRepeaterID = fv.RepeaterID === formValue.RepeaterID;
      if (!hasMatchingRepeaterID) {
        // Doesn't match repeater ID
        return false;
      }
    }*/

    return true;
  });

  if (formValueIndex > -1) {
    penData.Values.splice(formValueIndex, 1);
  }

  return penData.Values;
}
