import { Table } from "@tanstack/react-table";
import { Input } from "components/placement/Input";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { Option } from "components/placement/ComboboxPopover";
// import { XIcon } from "lucide-react";
// import Button from "components/placement/Button";
import { DataTableViewOptions } from "./data-table-view-options";
import { createRef, useRef } from "react";
import { MultiSelectRef } from "./multi-select";
import { DataTableExport } from "./data-table-export";

type FilterString = {
  id: string;
};

type FilterFacet = {
  id: string;
  options: Option[];
};

type DataTableToolbarProps<TData> = {
  table: Table<TData>;
  stringFilter?: FilterString;
  facets?: FilterFacet[];
};

export function DataTableToolbar<TData>({
  table,
  stringFilter,
  facets,
}: DataTableToolbarProps<TData>) {
  // const isFiltered = table.getState().columnFilters??.length > 0;

  const facetRefs = useRef(facets?.map(() => createRef<MultiSelectRef>()));

  // const resetFilters = () => {
  //   table.resetColumnFilters();

  //   facetRefs.current?.forEach((ref) => {
  //     ref.current?.reset();
  //   });
  // };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {stringFilter && (
          <Input
            placeholder="Filter..."
            value={
              (table.getColumn(stringFilter.id)?.getFilterValue() as string) ??
              ""
            }
            onChange={(event) =>
              table
                .getColumn(stringFilter.id)
                ?.setFilterValue(event.target.value)
            }
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}
        {facets?.map(
          (facet, index) =>
            table.getColumn(facet.id) && (
              <DataTableFacetedFilter
                ref={facetRefs.current?.[index]}
                key={facet.id}
                column={table.getColumn(facet.id)}
                title={facet.id}
                options={facet.options}
              />
            )
        )}
        {/* {isFiltered && (
          <Button onClick={resetFilters} className="h-8 px-2 lg:px-3">
            Reset
            <XIcon className="ml-2 h-4 w-4" />
          </Button>
        )} */}
      </div>
      <div className="flex items-center space-x-2">
        <DataTableExport table={table} />
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}

export type { FilterString, FilterFacet };
