import React from "react";
import { FieldError, useForm, UseFormReturn } from "react-hook-form";
import Card, { CardTitle } from "components/Card";
import { Input } from "components/placement/Input";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import Button from "components/placement/Button";
import { toast } from "react-toastify";
import {
  useDynamicFormBuilderSave,
  useDynamicFormBuilderGetOne,
} from "hooks/useDynamicForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { isNullEmptyOrWhitespace, uuid } from "helpers/common";
import FieldEditor from "./field/editor";
import TableRelationsEditor from "./relations/editor";
import { DynamicFormBuilderDef } from "./types";
import { builderSchema } from "./schema";

type DynamicFormBuilderEditorProps = {
  selectedFormId: DynamicFormBuilderDef["id"];
  onSave: (form: DynamicFormBuilderDef, close?: boolean) => void;
  onCancel: () => void;
};

const FormEditor = React.forwardRef<
  HTMLDivElement,
  DynamicFormBuilderEditorProps
>(({ selectedFormId, onSave, onCancel }, ref) => {
  const [formId, setFormId] = React.useState<string>(
    !isNullEmptyOrWhitespace(selectedFormId) ? selectedFormId : uuid()
  );
  const isNewForm = isNullEmptyOrWhitespace(selectedFormId);
  const shouldCloseRef = React.useRef<boolean>(false);

  const reactForm = useForm<DynamicFormBuilderDef>({
    defaultValues: {
      id: formId,
      name: "",
      fields: [],
      tableRelations: [],
    },
    resolver: zodResolver(builderSchema),
  });
  const { reset } = reactForm;

  const {
    data: formData,
    isLoading: isFormIsLoading,
    isFetched: isFormFetched,
    refetch: fetchForm,
  } = useDynamicFormBuilderGetOne({
    enabled: !isNewForm, // Fetch form data only if it's NOT a new form
    id: formId,
    onError: (errMessage) => {
      toast.error("An error occurred while fetching form!");
    },
  });

  const { mutate: mutateForm } = useDynamicFormBuilderSave({
    onSuccess: async () => {
      await fetchForm();
      toast.success("Form saved successfully!");
      onSave(reactForm.getValues(), shouldCloseRef.current);
    },
    onError: (errMessage) => {
      console.error("An error occurred while saving the form:", errMessage);
      toast.error(`An error occurred while saving the form: ${errMessage}`);
    },
  });

  React.useEffect(() => {
    if (!isNullEmptyOrWhitespace(selectedFormId)) {
      setFormId(selectedFormId);
    }
  }, [selectedFormId, setFormId]);

  React.useEffect(() => {
    if (formData) {
      reset(formData);
    }
  }, [formData, reset]);

  const onSubmit = async (data: DynamicFormBuilderDef) => {
    mutateForm(data);
  };

  const handleClickCancel = () => {
    onCancel();
  };

  const handleClickSave = (close?: boolean) => {
    shouldCloseRef.current = !!close;
    reactForm.handleSubmit(onSubmit)();
  };

  if (!isNewForm && (isFormIsLoading || !isFormFetched)) {
    return <Card>Loading...</Card>;
  }

  return (
    <div ref={ref} className="space-y-4">
      <Form {...reactForm}>
        <form className="space-y-4" noValidate>
          <Card>
            <CardTitle>{isNewForm ? "Create Form" : "Edit Form"}</CardTitle>
            <FormField
              control={reactForm.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription className="text-xs text-gray-500">
                    {formId}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Card>
          <TableRelationsEditor reactForm={reactForm} />
          <FieldEditor reactForm={reactForm} />
          <div className="flex justify-between">
            <Button
              type="button"
              variant="default"
              size="sm"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <div className="space-x-4">
              <Button
                type="button"
                variant="default"
                size="sm"
                onClick={() => handleClickSave()}
              >
                Save
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => handleClickSave(true)}
              >
                Save & Close
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
});

const Errors = ({
  reactForm,
  fieldIndex,
  validationIndex,
  errorState,
}: {
  reactForm: UseFormReturn<DynamicFormBuilderDef>;
  fieldIndex: number;
  validationIndex: number;
  errorState: any;
}) => {
  if (!errorState) {
    return null;
  }

  if (typeof errorState !== "object") {
    return null;
  }

  return (
    <div className="text-xs text-danger-600">
      {Object.entries(errorState).map(([key, error]) => {
        if (error === null || error === undefined) {
          return null;
        }

        if (typeof error === "object" && "message" in error) {
          return <div key={key}>{(error as FieldError).message}</div>;
        }

        if (typeof error === "string") {
          return <div key={key}>{error}</div>;
        }

        return null;
      })}
    </div>
  );
};

export { FormEditor as default, Errors };
