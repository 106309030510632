import { CardSkeleton } from "components/Card";
import SectionTitle from "components/SectionTitle";
import Search, { onDuplicateProps } from "components/config/standards/Search";
import Table, {
  standardTableRefProps,
} from "components/config/standards/Table";
import { Button } from "components/core/Buttons/Button";
import { tryInvalidateApiCache } from "helpers/common";
//import Button from "components/placement/Button";

import {
  schemaStandard,
  useConfigStandards,
  useStandardDuplicate,
  useStandardHeadersGetMany,
} from "hooks/useConfigStandards";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";

export type searchParamsSchema = {
  frequency: string;
  birdType: string;
  farmType: string;
  fromDate: string;
};

const ConfigStandardsPage = () => {
  const [data, setData] = React.useState<schemaStandard[] | null | undefined>(
    undefined
  );
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<searchParamsSchema>({
    frequency: "",
    birdType: "",
    farmType: "",
    fromDate: "",
  });

  const tableRef = React.useRef<standardTableRefProps>(null);

  const {
    data: standardHeadersData,
    isLoading: isLoadingStandardHeadersData,
    refetchData: refetchStandardHeadersData,
  } = useStandardHeadersGetMany({
    onSuccess: (variables) => {
      // console.log("onSuccess", variables);
    },
    onError: (errMessage, variables) => {
      console.error(errMessage);
      toast.error("An error occurred while fetching the search options.");
    },
  });

  const {
    mutate,
    isLoading,
    refetchData: refetchStandards,
  } = useConfigStandards({
    onSuccess: () => {
      // insert toast message
    },
    onError: (errorMessage) => {
      // insert toast message
    },
  });

  const { mutate: mutateStandardDuplicate } = useStandardDuplicate({
    onSuccess: async (variables) => {
      // onSearch({
      //   farmType,
      //   birdType,
      //   frequency,
      //   fromDate
      // });
      toast.success("Changes saved successfully!");

      await tryInvalidateApiCache("/api/configstandardheaders-get");

      refetchStandardHeadersData();

      setData(undefined);
    },
    onError: (errMessage, variables) => {
      console.error(errMessage);
      toast.error("An error occurred while saving the changes!");
    },
  });

  const columns = useMemo(() => {
    if (!standardHeadersData.columns) {
      return [];
    }

    return standardHeadersData.columns.filter(
      (d) =>
        d._filter.toLowerCase() ===
        `${searchParams.farmType}_${searchParams.birdType}_${searchParams.frequency}`.toLowerCase()
    );
  }, [standardHeadersData.columns, searchParams]);

  const handleSave = async () => {
    // console.log("handleSave");
    tableRef.current?.save();
  };

  const handleSearch = async (params: searchParamsSchema) => {
    setSearchParams(params);

    const { data } = await mutate(params);

    setData(data);
  };

  const handleDuplicate = async (params: onDuplicateProps) => {
    await mutateStandardDuplicate(params);
  };

  const handleIsProcessing = React.useCallback(
    (isProcessing: boolean) => {
      setIsProcessing(isProcessing);
    },
    [setIsProcessing]
  );

  const handleOnStandardsSave = async () => {
    const { data } = await refetchStandards(searchParams);
    setData(data);
  };

  return (
    <main className="flex flex-col flex-grow min-h-full">
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>Config Standards</SectionTitle>
        </div>

        <div className="col-span-full">
          <Search
            onSearch={handleSearch}
            onDuplicate={handleDuplicate}
            birdTypes={standardHeadersData.birdTypeOpts}
            fromDates={standardHeadersData.dateOpts}
          />
        </div>
        <div className="col-span-3 flex justify-between">
          <div />

          {!!data && (
            <Button disabled={isProcessing} type="button" onClick={handleSave}>
              {isProcessing ? "Processing..." : "Save"}
            </Button>
          )}
        </div>
        <div className="col-span-full">
          <TableOrLoading
            ref={tableRef}
            isLoading={isLoading || isLoadingStandardHeadersData}
            data={data}
            columns={columns}
            params={searchParams}
            onProcessing={handleIsProcessing}
            onStandardsSave={handleOnStandardsSave}
          />
        </div>
      </div>
    </main>
  );
};

export { ConfigStandardsPage as default };

const TableOrLoading = React.forwardRef<
  standardTableRefProps,
  {
    isLoading: boolean;
    data: schemaStandard[] | null | undefined;
    columns: {
      accessorKey: string;
      header: string;
    }[];
    params: searchParamsSchema;
    onProcessing: (isProcessing: boolean) => void;
    onStandardsSave: () => void;
  }
>(
  (
    { isLoading, data, columns, params, onProcessing, onStandardsSave },
    ref
  ) => {
    if (isLoading) {
      return <CardSkeleton />;
    }

    if (data === undefined) {
      return (
        <div className="text-center">
          <p className="mt-1 text-sm text-gray-500">
            Select parameters and click on the 'Search' button to view the data.
          </p>
        </div>
      );
    }

    if (data === null) {
      return (
        <div className="text-center">
          <p className="mt-1 text-sm text-gray-500">
            No data found for the selected parameters.
          </p>
        </div>
      );
    }

    return (
      <Table
        ref={ref}
        dataIn={data}
        columnsIn={columns}
        params={params}
        onProcessing={onProcessing}
        onSave={onStandardsSave}
      />
    );
  }
);
