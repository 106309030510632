import React from "react";
import Card, { CardTitle } from "components/Card";
import Button from "components/placement/Button";
import { PlusIcon } from "lucide-react";
import { DynamicFormListDef, DynamicFormListItemDef } from "./types";
import { useNavigate } from "react-router-dom";

interface FormListProps {
  forms: DynamicFormListDef;
  onSelect: (form: DynamicFormListItemDef) => void;
  onDelete: (formId: string) => void;
  onCreate: () => void;
}

const FormList: React.FC<FormListProps> = ({
  forms,
  onSelect,
  onDelete,
  onCreate,
}) => {
  return (
    <Card>
      <CardTitle>Forms</CardTitle>

      {forms.length === 0 && (
        <div className="text-center">
          <h3 className="mt-2 font-medium uppercase text-gray-900">
            No forms found!
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new form.
          </p>
          <div className="mt-6">
            <Button type="button" variant="primary" onClick={() => onCreate()}>
              <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
              New Form
            </Button>
          </div>
        </div>
      )}

      <ul className="divide-y divide-gray-100">
        {forms.map((form) => (
          <FormListItem
            key={form.id}
            form={form}
            onSelect={onSelect}
            onDelete={onDelete}
          />
        ))}
      </ul>
    </Card>
  );
};

function FormListItem({
  form,
  onSelect,
  onDelete,
}: {
  form: DynamicFormListItemDef;
  onSelect: (form: DynamicFormListItemDef) => void;
  onDelete: (formId: string) => void;
}) {
  const navigate = useNavigate();

  return (
    <li key={form.id} className="flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        <div className="min-w-0 flex-auto">
          <p
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => onSelect(form)}
            style={{ cursor: "pointer" }}
          >
            {form.name}
          </p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            {form.id}
          </p>
        </div>
      </div>
      <div className="hidden shrink-0 tablet:flex tablet:items-end">
        <Button
          variant="link"
          onClick={() => navigate(`/admin/config/${form.id}/new`)}
        >
          Preview
        </Button>
        <Button
          variant="link"
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you want to delete the form "${form.name}"?`
              )
            ) {
              onDelete(form.id);
            }
          }}
        >
          Delete
        </Button>
      </div>
    </li>
  );
}

export default FormList;
