import { useFieldArray, UseFormReturn } from "react-hook-form";
import Button from "components/placement/Button";
import { FieldListHeader } from "../field_list_header";
import { DynamicFormBuilderDef } from "../types";
import { TrashIcon } from "lucide-react";
import Card from "components/Card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { Input } from "components/placement/Input";

type TableRelationsEditorProps = {
  reactForm: UseFormReturn<DynamicFormBuilderDef>;
};

const TableRelationsEditor = ({ reactForm }: TableRelationsEditorProps) => {
  const { fields, append, remove } = useFieldArray({
    control: reactForm.control,
    name: "tableRelations",
  });

  const handleAddRelation = () => {
    append({
      formId: reactForm.getValues("id"),
      parentTable: "",
      childTable: "",
      parentColumn: "",
      childColumn: "",
    });
  };

  return (
    <div className="space-y-4">
      <FieldListHeader
        title="Table Relations"
        addButtonTitle="Add Relation"
        onAddField={handleAddRelation}
      />
      {fields.map((relation, fieldIndex) => (
        <div key={relation.id} className="table-relation space-y-4">
          <Card className="p-4">
            <div className="flex justify-between items-center">
              <h3 className="text-md font-medium">
                Table Relation {fieldIndex + 1}
              </h3>
              <Button
                type="button"
                variant="link"
                size="icon"
                onClick={() => remove(fieldIndex)}
              >
                <TrashIcon className="w-4 h-4" />
                <span className="sr-only">Remove</span>
              </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <FormField
                control={reactForm.control}
                name={`tableRelations.${fieldIndex}.parentTable`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Parent Table</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`tableRelations.${fieldIndex}.childTable`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Child Table</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`tableRelations.${fieldIndex}.parentColumn`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Parent Column</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`tableRelations.${fieldIndex}.childColumn`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Child Column</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default TableRelationsEditor;
