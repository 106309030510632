import { z } from "zod";
import { fieldSchema } from "../form_schema";

export const listSchema = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string(),
  })
);

export const builderFieldSchema = fieldSchema.extend({
  tableName: z.string().min(1, { message: "Table name is required" }),
});

export const builderTableRelationsSchema = z.object({
  formId: z.string().min(1),
  parentTable: z.string().min(1),
  childTable: z.string().optional(),
  parentColumn: z.string().min(1),
  childColumn: z.string().optional(),
});

export const builderSchema = z.object({
  id: z.string().min(1),
  name: z.string(),
  fields: z.array(builderFieldSchema),
  tableRelations: z.array(builderTableRelationsSchema).optional(),
});
