import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/placement/Form";
import Button from "components/placement/Button";
import DynamicField from "./dynamic_field";
import { DynamicFormData } from "./types";
import { generateZodSchema } from "components/dynamic_table_form/dynamic_form_util";
import { ColumnDef } from "@tanstack/react-table";
import { MinusIcon } from "lucide-react";
import { DataTable } from "components/ui/data-table";
import { ErrorMessage } from "@hookform/error-message";

type DynamicTableFormProps = {
  formDefinition: DynamicFormData;
  defaultValues: Record<string, any>[] | null;
  onCancel?: () => void;
  onSave?: (data: Record<string, any>) => void;
};
const DynamicTableForm: React.FC<DynamicTableFormProps> = ({
  formDefinition,
  defaultValues,
  onCancel,
  onSave,
}) => {
  const { fields: fieldDefs } = formDefinition;

  const dynamicSchema = generateZodSchema(fieldDefs);

  const form = useForm({
    resolver: zodResolver(dynamicSchema),
    defaultValues: {
      rows: [{}],
    },
  });
  const { reset } = form;

  const { fields, remove, replace } = useFieldArray({
    control: form.control,
    name: "rows",
  });

  // IMPORTANT: Not currently needed as we will pass the data via the defaultValues prop
  // and we don't expect the data to change after the form is mounted until save
  // Update the form and field array when fetchedData changes
  React.useEffect(() => {
    if (defaultValues) {
      reset({ rows: defaultValues });
      replace(defaultValues);
    }
  }, [defaultValues, reset, replace]);

  // // # Handle visibility
  // const formValues = form.watch("rows")
  // // Function to determine field visibility based on conditions
  // const shouldFieldBeVisible = (field, index) => {
  //   if (!field.visibilityConditions || field.visibilityConditions.length === 0) {
  //     return true
  //   }
  //   return field.visibilityConditions.every((condition) => {
  //     const dependsOnValue = formValues[index][condition.dependsOn]
  //     return dependsOnValue === condition.equalsValue
  //   })
  // }

  const columns = React.useMemo<
    ColumnDef<z.infer<typeof dynamicSchema>["rows"][0], unknown>[]
  >(
    () => [
      ...fieldDefs.map((fieldDef) => ({
        id: fieldDef.name,
        accessor: `${fieldDef.name}`,
        header: fieldDef.label,
        cell: ({ row }: { row: any }) => {
          return (
            <DynamicField
              key={fieldDef.id}
              form={form}
              row={row}
              fieldDef={fieldDef}
            />
          );
        },
      })),
      {
        id: "actions",
        accessor: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <Button
            type="button"
            variant="link"
            size="xs"
            onClick={() => remove(row.index)}
          >
            <MinusIcon className="w-4 h-4" />
            <span className="sr-only">Remove</span>
          </Button>
        ),
      },
    ],
    [fieldDefs, form, remove]
  );

  const onSubmit = (data: z.infer<typeof dynamicSchema>) => {
    onSave?.(data);
  };

  const handleClickCancel = () => {
    form.reset();
    // parent callback
    onCancel?.();
  };

  const hasErrors = Object.values(form.formState.errors).filter(
    (error) => error
  ).length;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DataTable data={fields} columns={columns} />
        <ErrorMessage
          errors={form.formState.errors.rows}
          name="multipleErrorInput"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p key={type}>{message}</p>
            ))
          }
        />
        {!!hasErrors && (
          <div className="text-danger-500">
            <p>There are errors in the form</p>
          </div>
        )}
        <div className="flex justify-between">
          <Button
            type="button"
            variant="default"
            size="sm"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" size="sm">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default DynamicTableForm;
