import { useEffect, useState } from "react";

const logoSrc = `/${process.env.REACT_APP_CUSTOMER_ID}/images/login-footer-logos.jpg`;

const SigninLogos: React.FC = () => {
  const [footerLogoSrc, setFooterLogoSrc] = useState("");

  useEffect(() => {
    fetch(logoSrc)
      .then((res) => {
        setFooterLogoSrc(
          res.status === 200 &&
            res.headers.get("content-type")?.startsWith("image/")
            ? logoSrc
            : ""
        );
      })
      .catch((err) => {
        // Do nothing
        // console.error(err);
      });
  }, []);

  if (!footerLogoSrc) {
    return null;
  }

  return (
    <img src={footerLogoSrc} alt={`${process.env.REACT_CUSTOMER_ID} logos`} />
  );
};

export default SigninLogos;
