import { type ChangeEventHandler, useState } from "react";
import { format, isAfter, isBefore, isValid } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { type DateRange, type SelectRangeEventHandler } from "react-day-picker";
import {
  DEFAULT_DATE_FORMAT,
  US_DATE_FORMAT,
  cn,
  isNullEmptyOrWhitespace,
  tryParseDateToLocalDateStr,
  tryParseLocalDateStrToDate,
} from "helpers/common";
import Button, { buttonVariants } from "components/placement/Button";
import Calendar from "components/placement/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/placement/Popover";
import { Input } from "components/placement/Input";
import { Label } from "components/placement/Label";
import ClearButton from "components/placement/ClearButton";
import { VariantProps } from "class-variance-authority";

export interface DatePickerWithRangeProps {
  className?: string;
  buttonSize?: VariantProps<typeof buttonVariants>["size"];
  onSelect?: (range: DateRange | undefined) => void;
  selected?: DateRange;
  numOfMonths?: number;
  onClear?: () => void;
}

/**
 * A component that displays a date picker with a range of dates.
 */
const DatePickerWithRange: React.FC<DatePickerWithRangeProps> = ({
  className,
  buttonSize,
  onSelect,
  selected,
  numOfMonths = 2,
  onClear,
}) => {
  const [fromInputValue, setFromInputValue] = useState<string>(
    tryParseDateToLocalDateStr(selected?.from)
  );
  const [toInputValue, setToInputValue] = useState<string>(
    tryParseDateToLocalDateStr(selected?.to)
  );

  const handleSelect: SelectRangeEventHandler = (range) => {
    onSelect?.(range);
    setFromInputValue(tryParseDateToLocalDateStr(range?.from));
    setToInputValue(tryParseDateToLocalDateStr(range?.to));
  };

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            size={buttonSize}
            className={cn("justify-start", className)}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {selected?.from ? (
              selected.to ? (
                <>
                  {format(selected.from, "LLL dd, y")} -{" "}
                  {format(selected.to, "LLL dd, y")}
                </>
              ) : (
                format(selected.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date...</span>
            )}
            <ClearButton onClick={onClear} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="range"
            selected={selected}
            onSelect={handleSelect}
            numberOfMonths={numOfMonths}
            defaultMonth={selected?.from || new Date()}
            // month={selected?.from || new Date()}
            initialFocus
          />
          {/* <Footer selected={selected} /> */}
          <FooterInputs
            selected={selected}
            onSelect={onSelect}
            fromValue={fromInputValue}
            toValue={toInputValue}
            onFromValueChange={setFromInputValue}
            onToValueChange={setToInputValue}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

// const Footer: React.FC<{
//   selected: DateRange | undefined;
// }> = ({ selected }) => {
//   if (!selected) {
//     return <span>Pick a date range</span>;
//   }

//   return (
//     <div className="p-3">
//       {selected?.from ? `${format(selected.from, "LLL dd, y")} -` : ""}{" "}
//       {selected?.to ? format(selected.to, "LLL dd, y") : ""}
//     </div>
//   );
// };

const FooterInputs: React.FC<{
  selected: DateRange | undefined;
  onSelect?: (range: DateRange | undefined) => void;
  fromValue: string;
  toValue: string;
  onFromValueChange: React.Dispatch<React.SetStateAction<string>>;
  onToValueChange: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  selected,
  onSelect,
  fromValue,
  toValue,
  onFromValueChange,
  onToValueChange,
}) => {
  const handleFromValueChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onFromValueChange(e.target.value);
  };

  const handleFromKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.key === "Enter") {
      handleSubmitFromValue();
    }
  };

  const handleSubmitFromValue = () => {
    const date = tryParseLocalDateStrToDate(fromValue);
    if (isNullEmptyOrWhitespace(date) || !isValid(date)) {
      return;
      // return onSelect?.({ from: undefined, to: undefined });
    }

    if (selected?.to && isAfter(date, selected.to)) {
      onSelect?.({ from: selected.to, to: date });
    } else {
      onSelect?.({ from: date, to: selected?.to });
    }
  };

  const handleToValueChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onToValueChange(e.target.value);
  };

  const handleToKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      handleSubmitToValue();
    }
  };

  const handleSubmitToValue = () => {
    const date = tryParseLocalDateStrToDate(toValue);
    if (isNullEmptyOrWhitespace(date) || !isValid(date)) {
      return;
    }

    if (selected?.from && isBefore(date, selected.from)) {
      onSelect?.({ from: date, to: selected.from });
    } else {
      onSelect?.({ from: selected?.from, to: date });
    }
  };

  return (
    <div className="p-3 flex justify-between items-center grow">
      <div className="relative">
        <Label variant="overlap" htmlFor="fromDate">
          From date
        </Label>
        <Input
          type="text"
          name="fromDate"
          id="fromDate"
          placeholder={`E.g. ${
            navigator.language === "en-US"
              ? US_DATE_FORMAT
              : DEFAULT_DATE_FORMAT
          }`.toLowerCase()}
          value={fromValue}
          onChange={handleFromValueChange}
          onKeyDown={handleFromKeyDown}
          onBlur={handleSubmitFromValue}
        />
      </div>
      <div className="w-6 border-b border-gray-300" />
      <div className="relative">
        <Label variant="overlap" htmlFor="toDate">
          To date
        </Label>
        <Input
          type="text"
          name="toDate"
          id="toDate"
          placeholder={`E.g. ${
            navigator.language === "en-US"
              ? US_DATE_FORMAT
              : DEFAULT_DATE_FORMAT
          }`.toLowerCase()}
          value={toValue}
          onChange={handleToValueChange}
          onKeyDown={handleToKeyDown}
          onBlur={handleSubmitToValue}
        />
      </div>
    </div>
  );
};

export { DatePickerWithRange as default };
