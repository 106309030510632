// FieldConditions.tsx
import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "components/placement/Form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { Input } from "components/placement/Input";
import Button from "components/placement/Button";
import { TrashIcon } from "lucide-react";
import Card from "components/Card";
import { FieldListHeader } from "../../field_list_header";
import { isNullEmptyOrWhitespace, uuid } from "helpers/common";
import { ErrorMessage } from "@hookform/error-message";
import { DynamicFormBuilderDef } from "../../types";

type FieldConditionsProps = {
  reactForm: UseFormReturn<DynamicFormBuilderDef>;
  fieldIndex: number;
};

const FieldConditions: React.FC<FieldConditionsProps> = ({
  reactForm,
  fieldIndex,
}) => {
  const {
    fields: fieldConditions,
    append,
    remove,
  } = useFieldArray({
    control: reactForm.control,
    name: `fields.${fieldIndex}.visibilityConditions`,
  });

  const handleAddCondition = () => {
    append({
      id: uuid(),
      dependsOn: "",
      equalsValue: "",
      fieldId: "",
      optionId: "",
    });
  };

  return (
    <div className="mt-4">
      <FieldListHeader
        title="Visibility Conditions"
        onAddField={handleAddCondition}
      />
      {fieldConditions.map((condition, conditionIndex) => {
        return (
          <Card key={condition.id} className="p-3 mt-2">
            <div className="flex justify-between items-center">
              <h5 className="text-sm font-medium">
                Condition {conditionIndex + 1}
              </h5>
              <Button
                type="button"
                size="icon"
                onClick={() => remove(conditionIndex)}
              >
                <TrashIcon className="w-4 h-4" />
                <span className="sr-only">Remove</span>
              </Button>
            </div>
            <ErrorMessage
              errors={reactForm.formState.errors}
              name={`fields.${fieldIndex}.visibilityConditions.${conditionIndex}.id`}
              render={({ message }) => (
                <div className="text-xs text-danger-600">{message}</div>
              )}
            />
            <ErrorMessage
              errors={reactForm.formState.errors}
              name={`fields.${fieldIndex}.visibilityConditions.${conditionIndex}.root`}
              render={({ message }) => (
                <div className="text-xs text-danger-600">{message}</div>
              )}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.visibilityConditions.${conditionIndex}.dependsOn`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Depends On</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="-- Select --" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {reactForm
                            .getValues()
                            .fields.filter(
                              (f) =>
                                !isNullEmptyOrWhitespace(f.name) &&
                                f.name !== field.name
                            )
                            .map((field: any) => (
                              <SelectItem key={field.id} value={field.name}>
                                {field.name}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription>
                      Field name this condition depends on
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.visibilityConditions.${conditionIndex}.equalsValue`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Equals Value</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Value to compare" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.visibilityConditions.${conditionIndex}.fieldId`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Field ID</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="-- Select Field --" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {reactForm
                            .getValues(`fields`)
                            .filter(
                              (f) =>
                                !isNullEmptyOrWhitespace(f.name) &&
                                f.id !== condition.fieldId
                            )
                            .map((field) => (
                              <SelectItem key={field.id} value={field.name}>
                                {field.name}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription>
                      Field ID this condition relates to
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name={`fields.${fieldIndex}.visibilityConditions.${conditionIndex}.optionId`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Option ID</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="-- Select Field --" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {reactForm
                            .getValues(`fields`)
                            .flatMap((f) => f.options)
                            .filter(
                              (o) =>
                                !isNullEmptyOrWhitespace(o) &&
                                !isNullEmptyOrWhitespace(o.label) &&
                                o.fieldId === condition.fieldId
                            )
                            .map((option: any) => (
                              <SelectItem key={option.id} value={option.id}>
                                {option.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription>
                      Field Option ID this condition relates to
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default FieldConditions;
