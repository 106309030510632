import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import Card from "components/Card";
import Button from "components/placement/Button";
import { TrashIcon } from "lucide-react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { Input } from "components/placement/Input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import FieldOptions from "./options/editor";
import FieldValidations from "./validation/editor";
import FieldConditions from "./condition/editor";
import { FieldListHeader } from "../field_list_header";
import { uuid } from "helpers/common";
import { DynamicFormBuilderDef } from "../types";

interface FieldEditorProps {
  reactForm: UseFormReturn<DynamicFormBuilderDef>;
}

const fieldTypesWithOptions = ["select", "radio", "checkbox"];

const FieldEditor: React.FC<FieldEditorProps> = ({ reactForm }) => {
  const {
    fields,
    append: appendField,
    remove: removeField,
  } = useFieldArray({
    control: reactForm.control,
    name: "fields",
  });

  const handleAddField = () => {
    const formId = reactForm.getValues("id");

    appendField({
      id: uuid(),
      formId,
      name: "",
      tableName: "",
      label: "",
      type: "text",
      options: [],
      order: fields.length,
    });
  };

  return (
    <div className="space-y-4">
      <FieldListHeader title="Fields" onAddField={handleAddField} />
      {fields.map((fieldItem, fieldIndex) => {
        return (
          <div className="space-y-4" key={fieldItem.id}>
            <Card key={fieldItem.id} className="p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-md font-medium">Field {fieldIndex + 1}</h3>
                <Button
                  type="button"
                  variant="link"
                  size="icon"
                  onClick={() => removeField(fieldIndex)}
                >
                  <TrashIcon className="w-4 h-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <FormField
                  control={reactForm.control}
                  name={`fields.${fieldIndex}.label`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Label</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onBlur={(ev) => {
                            if (
                              reactForm.getValues(
                                `fields.${fieldIndex}.name`
                              ) === ""
                            ) {
                              reactForm.setValue(
                                `fields.${fieldIndex}.name`,
                                ev.target.value
                                  .toLowerCase()
                                  .replace(/[^a-z0-9]/g, "_")
                              );
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={reactForm.control}
                  name={`fields.${fieldIndex}.name`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={reactForm.control}
                  name={`fields.${fieldIndex}.tableName`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Table Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={reactForm.control}
                  name={`fields.${fieldIndex}.type`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Type</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            const previousFieldType = field.value;
                            field.onChange(value);

                            if (
                              fieldTypesWithOptions.includes(
                                previousFieldType
                              ) &&
                              !fieldTypesWithOptions.includes(value)
                            ) {
                              reactForm.setValue(
                                `fields.${fieldIndex}.options`,
                                []
                              );
                            }
                          }}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="-- Select --" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="text">Text</SelectItem>
                            <SelectItem value="number">Number</SelectItem>
                            <SelectItem value="textarea">Textarea</SelectItem>
                            <SelectItem value="select">Select</SelectItem>
                            <SelectItem value="radio">Radio</SelectItem>
                            <SelectItem value="checkbox">Checkbox</SelectItem>
                            <SelectItem value="date">Date</SelectItem>
                            <SelectItem value="time">Time</SelectItem>
                            <SelectItem value="datetime">DateTime</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={reactForm.control}
                  name={`fields.${fieldIndex}.order`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Order</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" placeholder="Order" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </Card>
            {(reactForm.watch(`fields.${fieldIndex}.type`) === "select" ||
              reactForm.watch(`fields.${fieldIndex}.type`) === "radio" ||
              reactForm.watch(`fields.${fieldIndex}.type`) === "checkbox") && (
              <FieldOptions reactForm={reactForm} fieldIndex={fieldIndex} />
            )}
            <FieldValidations reactForm={reactForm} fieldIndex={fieldIndex} />
            <FieldConditions reactForm={reactForm} fieldIndex={fieldIndex} />
          </div>
        );
      })}
    </div>
  );
};

export default FieldEditor;
