import React, { useState, createContext, useMemo } from "react";
import { useUser } from "hooks/useUser";
import { usePrefetchFarmStandards } from "hooks/useFarmStandard";

export interface ISchedule {
  [key: string]: any;
}

export interface IAppDataContext {
  pageSubtitle: string;
  setPageSubtitle: (subtitle: string) => void;
  pageTitle: string;
  setPageTitle: (title: string) => void;
}

export const AppDataContext = createContext({} as IAppDataContext);

interface AppDataProviderProps {
  children: React.ReactNode;
}

export function AppDataProvider({ children }: AppDataProviderProps) {
  const { isSignedIn } = useUser();

  usePrefetchFarmStandards({
    enabled: isSignedIn,
  });

  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");

  const contextValue = useMemo(
    () => ({
      pageSubtitle,
      setPageSubtitle,
      pageTitle,
      setPageTitle,
    }),
    [pageSubtitle, pageTitle]
  );

  return (
    <AppDataContext.Provider value={contextValue}>
      {children}
    </AppDataContext.Provider>
  );
}
